import {
    randomPickupItems,
    removeLinkTags,
    getMediaUrl,
    getSases
} from "../../CommonFunction"

import {
    getWordImage
} from "../../WordDataFunction"

export function buildQuizWordProps(data) {

    const activity = data.isRandom ? "quizRandom" : "quiz"
    const quizIndex = data.answers.length
    const quizCount = data.quizWords.length

    const quizData = data.quizWords[quizIndex]
    // quizData format
    //
    // {
    //     "wordId": wordId,
    //     "displayName": displayName,
    //     "cId": cId,
    //     "selectedMeaningId": selectedMeaningId,
    //     "imageFile": imageFile,
    //     "pos": pos,
    //     "meaning": meaning,
    //     "isStarred": isStarred,
    //     "quiz": {
    //          "meaning": meaning,
    //          "meaningCandidates": meaningCandidates,
    //          "sentenceId": sentenceId,
    //          "hasImage": hasImage,
    //          "fitbSentence": fitbSentence,
    //          "fitb": fitb,
    //          "fitbCandidates": fitbCandidates
    //     }
    //     "quizHistory": [
    //         {"rating": rating, "updateAt": "yyyy-MM-ddTHH:mm:ss JST"},
    //         ...
    //     ]
    // }
    
    const wordId = quizData.wordId
    const cId = quizData.cId
    const displayName = quizData.displayName
    const meaning = removeLinkTags(quizData.meaning)
    const wordImageUrl = getWordImage(quizData.imageFile)

    const meaningCandidates = quizData.quiz.meaningCandidates
    const fitb = quizData.quiz.fitb
    const fitbSentence = quizData.quiz.fitbSentence
    const fitbCandidates = quizData.quiz.fitbCandidates
    const sentenceImageUrl = quizData.quiz.hasImage ?
        `${getMediaUrl()}/media/image/sentence/${quizData.quiz.sentenceId}.png?${getSases().mastervocabulary}`
        :
        ""
    const quizHistory = quizData.quizHistory

    ///////////////////////////////////////
    // set up meaningOptions

    let meaningOptions = [
        {meaning, status: "correct"}
    ]
    if (meaningCandidates) {
        meaningCandidates.forEach((meaning) => {
            meaningOptions.push({meaning, status: "incorrect"})
        })
    }
    meaningOptions = randomPickupItems(meaningOptions, meaningOptions.length)
    meaningOptions.push({meaning: "わかりません", status: "incorrect"})
    meaningOptions.push({meaning: "時間切れ", status: "timeout"})

    let correctMeaningIndex = -1
    for (let i = 0; i < meaningOptions.length; ++i) {
        if (meaningOptions[i].status === "correct") {
            correctMeaningIndex = i
            break
        }
    }

    ///////////////////////////////////////
    // set up fitbOptions

    let fitbOptions = [
        {fitb, status: "correct"}
    ]
    if (fitbCandidates) {
        fitbCandidates.forEach((fitb) => {
            fitbOptions.push({fitb, status: "incorrect"})
        })
    }
    fitbOptions = randomPickupItems(fitbOptions, fitbOptions.length)
    fitbOptions.push({fitb: "わかりません", status: "incorrect"})
    fitbOptions.push({fitb: "時間切れ", status: "timeout"})

    let correctFitbIndex = -1
    for (let i = 0; i < fitbOptions.length; ++i) {
        if (fitbOptions[i].status === "correct") {
            correctFitbIndex = i
            break
        }
    }

    ///////////////////////////////////////

    return {
        activity,
        quizIndex,
        quizCount,
        wordId,
        cId,
        displayName,
        meaning,
        wordImageUrl,
        meaningOptions,
        correctMeaningIndex,
        fitbSentence: fitbSentence,
        fitb,
        fitbOptions,
        correctFitbIndex,
        sentenceImageUrl,
        quizHistory
    }    
}