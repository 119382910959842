import React from "react";
import {
    Box,
    Text,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    Link
} from "@chakra-ui/react";
import {
    ExternalLinkIcon
} from "@chakra-ui/icons"

import { useUserContext } from "../../context/userContext"
import {
    convertDateInIso8601Jst
} from "../../CommonFunction"

const ExpiredDialog = (props) => {

    const {
        getExpiredAt
    } = useUserContext()    

    const licensePurchaseLink = "https://www.sora2.net"

    const dt = new Date(getExpiredAt())
    dt.setDate(dt.getDate() + 29)
    const deletedOnString = convertDateInIso8601Jst(dt).substring(0, 10)
    
    return (
        <Modal
            closeOnOverlayClick={false}
            isOpen={props.isOpen} 
            onClose={props.onClose}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>ライセンス有効期限切れのお知らせ</ModalHeader>
                <ModalBody>
                    <Box
                        fontSize="sm"
                    >
                        <Text>いつも英活アプリをご利用いただきありがとうございます。</Text>
                        <Text>
                            先般ご入手頂いたライセンスの利用有効期限を過ぎたため、アプリをご利用頂けなくなりました。<br/>
                            引き続きご利用になりたい場合は、ライセンスをご購入ください。</Text>
                        <Text>
                            <Link 
                                href={licensePurchaseLink}
                                isExternal={true}
                                color="blue.500"
                            >
                                有料ライセンスご購入ページへのリンク<ExternalLinkIcon m="2px 0px 8px 2px" />
                            </Link>
                        </Text>
                        <Text>なおアプリご利用中に保存されたデータは {deletedOnString} まで保管されます。{deletedOnString} までにライセンスをご購入頂きますと、現在保管しているデータを引き続きご利用頂くことができます。</Text>
                        <Text>
                            {deletedOnString} にデータは削除されます。データ削除日以降にライセンスをご購入頂いても、削除されたデータの復元は出来かねますので予めご了承ください。
                        </Text>
                    </Box>
                </ModalBody>
            </ModalContent>
        </Modal>        
    )
}

export default ExpiredDialog