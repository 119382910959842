import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    HStack,
    Stack,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Checkbox
} from "@chakra-ui/react";
import { useAuthContext } from "../context/authContext"
import useAsyncLocalStorage from "../hooks/useAsyncLocalStorage";

const LicenseAgreementDialog = (props) => {

    const {
        setRequestType
    } = useAuthContext()

    const {
        clearLocalStorage
    } = useAsyncLocalStorage()

    const [htmlContent, setHtmlContent] = useState("");
    useEffect(() => {
        // read a HTML file
        fetch("/terms_of_service.html")
          .then((response) => response.text())
          .then((text) => setHtmlContent(text));
        console.log(htmlContent)
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);    

    const [licenseAgreed, setLicenseAgreed] = useState(false)

    return (
        <Modal
            closeOnOverlayClick={false}
            isOpen={props.isOpen} 
            onClose={props.onClose}
        >
            <ModalOverlay />
            <ModalContent
                maxW="340px"
            >
                <ModalCloseButton 
                    onClick={() => {
                        props.onClose()
                    }}
                />                
                <ModalBody
                    m="36px 0px 0px 0px"
                    p="0px 8px 0px 8px"
                    style={{ maxHeight: "400px", overflowY: "auto"}}
                >
                    <Box
                        border="1px"
                        borderColor="gray.300"
                    >
                        <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
                    </Box>
                </ModalBody>
                <ModalFooter
                    justifyContent="flex-start"
                >
                    <Stack>
                        <Checkbox
                            isChecked={licenseAgreed}
                            onChange={(e) => {
                                setLicenseAgreed(e.target.checked)
                            }}
                            m="8px 0px 8px 0px"
                        >
                            チェックして利用規約に合意する
                        </Checkbox>
                        <HStack>
                            <Button
                                isDisabled={!licenseAgreed}
                                colorScheme="red"
                                onClick={() => 
                                    Promise.resolve()
                                        .then(() => clearLocalStorage())
                                        .then(() => setRequestType("signUp"))
                                        // .then(async (res) => console.log(`getRequestType: ${await getRequestType()}`))
                                        .then(() => {
                                            // if (userAgentInfo.os === "ios") {
                                                props.onLoginRequestDialogOpen()
                                            // } else {
                                            //     await instance.loginRedirect({
                                            //         ...loginRequest,
                                            //         prompt: "create"
                                            //     }).catch((error) => console.log(error))
                                            //     props.onClose()
                                            // }
                                        })
                                        .then(() => props.onClose())
                                }
                            >
                                アカウント作成
                            </Button>
                            <Button
                                colorScheme="gray"
                                onClick={() => {
                                    props.onClose()
                                }}
                            >
                                キャンセル
                            </Button>
                        </HStack>
                    </Stack>
                </ModalFooter>
            </ModalContent>
        </Modal>        
    )
}

export default LicenseAgreementDialog