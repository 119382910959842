import React, { useEffect, useState } from "react";
import {
    Box,
    Text,
    HStack,
    Stack,
    Card,
    CardHeader,
    Heading,
    StackDivider,
    Flex,
    useRadioGroup
} from "@chakra-ui/react";

import "../../hooks/useApi"
import "../../App.css";
import { useUserContext } from "../../context/userContext"
import {
    getEpisodeImageByWordId
} from "../../WordDataFunction";
import BackButton from "../BackButton";
import RadioCard from "../RadioCard";
import WordSnippetDialog from "../WordSnippetDialog"
import HeaderComponent from "../HeaderComponent";


const WordHistory = (props) => {
    const {
        getUserName,
        getWordHistoryFilter,
        setWordHistoryFilter
    } = useUserContext()

    const [, setViewSettings] = useState({"isSnippetOpen": false})

    const [selectedWord, setSelectedWord] = useState(
        {
            cId: "",
            wordId: "",
            displayName: "",
            meaning: "",
            wordImageUrl: "",
            index: -1
        }
    )

    const getActivityTag = (activity) => {
        let label = ""
        let bgColor = "orange.200"
        let color = "black"

        switch (activity) {
            case "quiz":
            case "quizRandom":
                label = "クイズ"
                bgColor = "orange.200"
                break
            case "study":
                label = "学習"
                bgColor = "blue.200"
                break
            case "browse":
                label = "閲覧"
                bgColor = "green.200"
                break
            case "register":
                label = "登録"
                bgColor = "yellow.200"
                break
            case "update":
                label = "更新"
                bgColor = "Cyan.200"
                break
            case "delete":
                label = "削除"
                bgColor = "black"
                color = "white"
                break
            default:
                label = activity
                break
        }

        return (
            <Box
                rounded="full"
                color={color}
                bgColor={bgColor}
                p="0px 5px 0px 5px"
                width="55px"
                textAlign="center"
                fontSize="xs"
            >
                {label}
            </Box>
        )
    }

    const dailyStats = () => {

        const dailyStatsDict = {}

        props.dailyGroupHistory.forEach((items) => {
            const studyWordCount = items.reduce((total, item) => (item.activity === "study" ? total + 1 : total), 0)
            const quizWordCount = items.reduce((total, item) => (item.activity.startsWith("quiz") ? total + 1 : total), 0)
            const quizCorrectCount = items.reduce((total, item) => (item.answerStatus === "correct" ? total + 1 : total), 0)
            const registerWordCount = items.reduce((total, item) => (item.activity === "register" ? total + 1 : total), 0)
            const quizAccuracyRate = quizWordCount > 0 ?
                `${Math.round(quizCorrectCount / quizWordCount * 1000) / 10} % = (${quizCorrectCount} / ${quizWordCount})`
                :
                "-"

            dailyStatsDict[items[0].dateString] = {
                studyWordCount,
                quizWordCount,
                quizAccuracyRate,
                registerWordCount
            }
        })

        return dailyStatsDict
    }

    ///////////////////////////////////////////////////
    // filter
    // possible 'itemFilter' values and the behaviors
    // "" : showing all items 
    // "quiz": showing only quiz items
    // "quizIncorrect": showing only quiz items with incorrect answer
    // "register": showing only registered items
    // "study": showring only studied items

    const filterOptions = [
        "",
        "quiz",
        "quizIncorrect",
        "register",
        "study"
    ]
    const filterLabels = (value) => {
        switch(value) {
            case "":
                return "全て"
            case "quiz":
                return "クイズ"
            case "quizIncorrect":
                return "クイズ不正解"
            case "register":
                return "登録"
            case "study":
                return "学習"
            default:
                return "　　"
        }
    }

    const handleFilterChange = (value) => {
        setWordHistoryFilter(value)
    }

    const { value, getRootProps, getRadioProps } = useRadioGroup({
        name: "itemFilter",
        defaultValue: getWordHistoryFilter(),
        onChange: handleFilterChange
    })

    const itemFilterGroup = getRootProps()
    ///////////////////////////////////////////////////


    ///////////////////////////////////////////////////
    // scrolling
    const getScrollPosition = () => {
        return window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
    }

    useEffect(() => {
        setViewSettings({isSnippetOpen: false})
        setTimeout(()=> {
            window.scrollTo(0, props.scrollPosition)
        }, 100)
        // window.scrollTop = props.scrollPosition
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    ///////////////////////////////////////////////////


    // const dispatch = async (wordId, displayName) => {
    //     props.dispatch({
    //         type: "selectWord",
    //         data: {
    //             previous: {},
    //             current: {
    //                 wordId,
    //                 query: displayName,
    //                 scrollPosition: getScrollPosition()
    //             }
    //         }
    //     })
    // }    

    return (
        <Stack
            margin="0%"
            textAlign="left"
            p="1"
            rounded="md"
            width="100%"
        >
            <HeaderComponent
                setLaunchSubApp={props.setLaunchSubApp}
                content={
                    <Text
                        margin="2px 0px 0px 8px"
                        fontSize="xl"
                    >
                        {getUserName()} さんの履歴
                    </Text>
                }
            />

            <Flex justifyContent="center" alignItems="start" flexWrap="wrap" {...itemFilterGroup}>
                {filterOptions.map((value) => {
                    const radio = getRadioProps({ value })
                    return (
                        <RadioCard key={value} {...radio}>
                            {filterLabels(value)}
                        </RadioCard>
                    )
                })}
            </Flex>

            <Box
                rounded="md"
                border="0px 0px 10px 0px"
                borderColor="blue.100"
                padding={"0px 10px 30px 10px"}
            >
                <Stack>
                    {
                        !props.dailyGroupHistory ?
                            <Box>
                                履歴がありません。
                            </Box>
                            :
                            props.dailyGroupHistory.map((items, cardIndex) =>
                                <Card
                                    key={`${cardIndex}`}
                                >
                                    <CardHeader>
                                        <Stack divider={<StackDivider />}>
                                            <Heading
                                                size="md"
                                                color={items[0].dayColor}
                                            >
                                                {items[0].dateString}
                                            </Heading>
                                                value === "" ?
                                            <Stack>
                                                {
                                                    ["", "study"].includes(value) ?
                                                        <Box>
                                                            学習単語数: {dailyStats()[items[0].dateString].studyWordCount}
                                                        </Box>
                                                        :
                                                        <></>
                                                }
                                                {
                                                    ["", "quiz", "quizIncorrect"].includes(value) ?
                                                        <Box>
                                                            クイズ単語数: {dailyStats()[items[0].dateString].quizWordCount}
                                                        </Box>
                                                        :
                                                        <></>
                                                }
                                                {
                                                    ["", "quiz", "quizIncorrect"].includes(value) ?
                                                        <Box>
                                                            クイズ正解率: {dailyStats()[items[0].dateString].quizAccuracyRate}
                                                        </Box>
                                                        :
                                                        <></>
                                                }
                                                {
                                                    ["", "register"].includes(value) ?
                                                        <Box>
                                                            登録単語数: {dailyStats()[items[0].dateString].registerWordCount}
                                                        </Box>
                                                        :
                                                        <></>
                                                }
                                            </Stack>
                                            :
                                            <></>
                                            {
                                                items.flatMap((item, index) => 
                                                    value === "" ||
                                                    value === item.activity ||
                                                    (value === "quizIncorrect" && item.activity === "quiz" && item.answerStatus === "incorrect")
                                                        ? 
                                                        <HStack
                                                            key={`${cardIndex}_${index}`}
                                                            onClick={() => {
                                                                // if (item?.cId && item.activity !== "delete") {
                                                                //     onClick(item.cId, item.displayName)
                                                                // }
                                                                    if (item?.cId && item.activity !== "delete") {
                                                                        setSelectedWord(
                                                                            {
                                                                                cId: item.cId,
                                                                                wordId: item?.wordId,
                                                                                displayName: item?.displayName,
                                                                                meaning: item?.selectedMeaning,
                                                                                wordImageUrl: getEpisodeImageByWordId(item?.wordId),
                                                                                index,
                                                                                word: item
                                                                            }
                                                                        )
                                                                        setViewSettings({isSnippetOpen: true})
                                                                    }
                                                                }}
                                                        >
                                                            <Box
                                                                width="50px"
                                                            >
                                                                {item.timeString}
                                                            </Box>
                                                            <HStack
                                                                width="90px"
                                                            >
                                                                <Box>
                                                                    {getActivityTag(item.activity)}
                                                                </Box>
                                                                {
                                                                    item.answerStatus ?
                                                                        item.answerStatus === "correct" ?
                                                                            <Box
                                                                                color="green.500"
                                                                                fontSize="md"
                                                                            >
                                                                                ◎
                                                                            </Box>
                                                                            :
                                                                            <Box
                                                                                color="red.500"
                                                                                fontSize="md"
                                                                            >
                                                                                ×
                                                                            </Box>
                                                                        :
                                                                        <></>
                                                                }
                                                            </HStack>
                                                            <Box
                                                                flex="1"
                                                            >
                                                                <Text
                                                                    m="0px"
                                                                    noOfLines={1}
                                                                >
                                                                    {item.displayName}
                                                                </Text>
                                                            </Box>
                                                        </HStack>
                                                        :
                                                        []
                                                )
                                            }
                                        </Stack>
                                    </CardHeader>
                                </Card>
                            )
                    }

                    <WordSnippetDialog
                        cId={selectedWord.cId}
                        wordId={selectedWord.wordId}
                        displayName={selectedWord.displayName}
                        meaning={selectedWord.meaning}
                        isStarred={selectedWord.isStarred}
                        wordImageUrl={selectedWord.wordImageUrl}
                        selectedWord={selectedWord}
                        setSelectedWord={setSelectedWord}
                        getScrollPosition={getScrollPosition}
                        dispatch={props.dispatch}
                    />

                    <Box
                        m="20px 0px 0px 0px"
                    >
                        <BackButton
                            dispatch={props.dispatch}
                        />
                    </Box>
                </Stack>
            </Box>
        </Stack>
    )
}

export default WordHistory


