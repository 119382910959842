import {
    getValueFromWord,
    chooseSelectedMeaningSentence
} from "../../WordDataFunction"
import { removeLinkTags } from "../../CommonFunction"

export function buildStudyWordProps(wordData) {
    
    const selectedPosMeaningSentence = chooseSelectedMeaningSentence(wordData)

    const posIndex = selectedPosMeaningSentence.posIndex
    const meaningIndex = selectedPosMeaningSentence.posIndex
    const sentenceIndex = selectedPosMeaningSentence.sentenceIndex
    const anotherSentenceIndex = selectedPosMeaningSentence.anotherSentenceIndex

    const anotherSentenceJa = getValueFromWord(
        wordData,
        "sentenceJa",
        posIndex,
        meaningIndex,
        anotherSentenceIndex
    )

    const wordAudioUrls = getValueFromWord(wordData, "posWordAudio", posIndex)
    const wordImageUrl = getValueFromWord(wordData, "episodeImage")
    const sentenceAudioUrl = getValueFromWord(
        wordData,
        "sentenceAudio",
        posIndex,
        meaningIndex,
        sentenceIndex
    )
    const sentenceImageUrl = getValueFromWord(
        wordData,
        "sentenceImage",
        posIndex,
        meaningIndex,
        sentenceIndex
    )

    const sentenceEn = getValueFromWord(
        wordData,
        "sentence",
        posIndex,
        meaningIndex,
        sentenceIndex
    )
    const sentenceJa = getValueFromWord(
        wordData,
        "sentenceJa",
        posIndex,
        meaningIndex,
        sentenceIndex
    )

    const initialFieldStatus = {
        listenWordAudio: wordAudioUrls.length > 0 ? 0 : 1,
        recordWordAudio: wordAudioUrls.length > 0 ? 0 : 1,
        listenSentenceAudio: sentenceAudioUrl && sentenceAudioUrl.length > 0 ? 0 : 1,
        recordSentenceAudio: sentenceAudioUrl && sentenceAudioUrl.length > 0 ? 0 : 1,
        matchWordText: 0,
        writingText: anotherSentenceJa && anotherSentenceJa.length > 0 ? 0 : 1
    }

    const stepsAvailability = {
        wordAudio: wordAudioUrls.length > 0,
        matchWordText: true,
        sentenceAudio: sentenceAudioUrl && sentenceAudioUrl.length > 0 ? true : false,
        writingText: anotherSentenceJa && anotherSentenceJa.length > 0
    }

    return {
        wordId: getValueFromWord(wordData, "wordId"),
        posIndex,
        meaningIndex,
        sentenceIndex,
        anotherSentenceIndex,
        cId: getValueFromWord(wordData, "cId"),
        displayName: getValueFromWord(wordData, "displayName"),
        pos: getValueFromWord(wordData, "pos", posIndex, meaningIndex),
        ipa: getValueFromWord(wordData, "posWordIpa", posIndex),
        episode: getValueFromWord(wordData, "episode"),
        meaning: removeLinkTags(
            getValueFromWord(
                wordData,
                "meaning",
                posIndex,
                meaningIndex
            )
        ),
        // tags: getValueFromWord(
        //     wordData,
        //     "tags",
        //     selectedMeaningSentence.meaningIndex
        // ),
        // synonyms: getValueFromWord(
        //     wordData,
        //     "synonyms",
        //     selectedMeaningSentence.meaningIndex
        // ),
        // usages: getValueFromWord(
        //     wordData,
        //     "usages",
        //     selectedMeaningSentence.meaningIndex
        // ),
        // compliments: getValueFromWord(
        //     wordData,
        //     "compliments",
        //     selectedMeaningSentence.meaningIndex
        // ),
        sentence: sentenceEn,
        sentenceJa: sentenceJa,
        notes: getValueFromWord(wordData, "notes"),
        anotherSentenceJa,
        wordAudioUrls,
        wordImageUrl,
        sentenceAudioUrl,
        sentenceImageUrl,
        initialFieldStatus,
        stepsAvailability
    }    
}