import React, { useState, useEffect } from "react"
import { 
    ChakraProvider,
    Flex,
    Box,
    Center,
    useDisclosure
} from "@chakra-ui/react"
import './App.css';
import NavigationBar from "./components/NavigationBar"
import Footer from "./components/Footer"

import RoutesSettings from "./RoutesSettings";

import useApi from "./hooks/useApi"
import useSubmit from "./hooks/useSubmit"

import { 
    getUserAgentInfo
} from "./CommonFunction"
import { useAuthContext } from "./context/authContext";
import { useFullCoverSpinnerContext } from "./context/fullCoverSpinnerContext"
import { useErrorContext } from "./context/errorContext";
import useAsyncLocalStorage from "./hooks/useAsyncLocalStorage";

import FullCoverSpinner from "./components/FullCoverSpinner"
import LicenseAgreementDialog from "./components/LicenseAgreementDialog";
import LoginRequestDialog from "./components/LoginRequestDialog"
import ErrorDialog from "./components/ErrorDialog"


const MainContent = (props) => {

    const {
        getBearerToken,
        getMsalAuthResponse
    } = useSubmit()

    const {
        isSignIn,
        switchToSignIn,
        validateAccessToken,
        wasSignIn
    } = useAuthContext()

    const {
        openRequest,
        closeRequest
    } = useFullCoverSpinnerContext()

    const {
        initBearerToken,
        retrieveUser,
        getWordStatus,
        getAvatarImage
    } = useApi()

    const {
        errorMessage
    } = useErrorContext()

    const {
        getAuthInfo
    } = useAsyncLocalStorage()

    //////////////////////////////////////////////////////
    // browser support
    const [userAgentInfo, setUserAgentInfo] = useState({
        isSupported: false,
        os: "unknown",
        browserName: "unknown",
        browserVersion: "unknown",
        isChecked: false
    })
    //////////////////////////////////////////////////////

    //////////////////////////////////////////////////////

    useEffect(() => {
        if (!userAgentInfo.isChecked) {
            const uaInfo = getUserAgentInfo()
            setUserAgentInfo({
                ...uaInfo,
                isChecked: true
            })
        }

        if (userAgentInfo.isSupported) {
            (async() => {
                const authInfo = await getAuthInfo()

                const accessToken = authInfo?.azureInfo?.accessToken
                const issuer = authInfo?.issuerInfo?.issuer
                const requestType = authInfo?.requestType

                // for microsoft.com
                const code_verifier = authInfo?.issuerInfo?.code_verifier
                
                // for line.me : state parameter
                const lineState = authInfo?.issuerInfo?.state

                console.log(`accessToken: ${accessToken ? "exists" : "does not exist"}, issuer: ${issuer}, requestType: ${requestType}`)
                //////////////////////////////////////////////////////

                let requestId = null

                if (!accessToken) {
                    switch(issuer) {
                        case "microsoft.com":
                            if (requestType === "signUp") {
                                Promise.resolve()
                                    .then(() => requestId = openRequest())
                                    .then(() => getMsalAuthResponse())
                                    .then((response) => {
                                        if (response) {
                                            return initBearerToken(
                                                "signUp",
                                                "microsoft.com",
                                                null,
                                                null,
                                                response.accessToken
                                            )
                                        } else {
                                            return false
                                        }})
                                    .then(async (result) => {
                                        console.log(`result: ${result}`)
                                        if (result) {
                                            await switchToSignIn()
                                            await retrieveUser()
                                        }
                                        return result
                                    })
                                    .then(async (result)=> {
                                        if (result) {
                                            await getWordStatus()
                                            await getAvatarImage()
                                        }
                                        // return result
                                    })
                                    // .then((result) => {
                                    //     if (result) {
                                    //         switchToSignIn()
                                    //     }
                                    // })
                                    .catch((error) => console.log(error))
                                    .finally(() => {
                                        closeRequest(requestId)
                                    })
                            } else {
                                // url?code=... is redirect url from microsoft.com
                                const urlParams = new URLSearchParams(window.location.search);
                                const authCode = urlParams.get('code')
            
                                if (authCode) {
                                    ////////////////////////////////////////////
                                    // Microsoft account sign-in scenario

                                    Promise.resolve()
                                        .then(() => requestId = openRequest())
                                        .then(() => initBearerToken(
                                            requestType,
                                            "microsoft.com",
                                            authCode,
                                            code_verifier
                                        ))
                                        .then(async (result) => {
                                            if (result) {
                                                console.log(`result: ${result}`)
                                                await switchToSignIn()
                                                await retrieveUser()
                                            }
                                        })
                                        .then(async ()=> {
                                            await getWordStatus()
                                            await getAvatarImage()
                                        })
                                        .catch((error) => console.log(error))
                                        .finally(() => {
                                            closeRequest(requestId)
                                            window.location.href = `${window.location.origin}${window.location.pathname}`
                                        })
                                } else {
                                    //
                                }
                            }
                            break
                        case "line.me":

                            // url?code=... is redirect url from line.me
                            const urlParams = new URLSearchParams(window.location.search);
                            const authCode = urlParams.get('code')
                            const responseLineState = urlParams.get('state')

                            if (authCode 
                                    && responseLineState
                                    && responseLineState === lineState)
                            {
                                Promise.resolve()
                                .then(() => requestId = openRequest())
                                .then(() => 
                                    initBearerToken(
                                        requestType,
                                        "line.me",
                                        authCode
                                    )
                                )
                                .then(async (result) => {
                                    console.log(`result: ${result}`)
                                    if (result) {
                                        await switchToSignIn()
                                        await retrieveUser()
                                    }
                                    return result
                                })
                                .then(async (result)=> {
                                    if (result) {
                                        await getWordStatus()
                                        await getAvatarImage()
                                    }
                                })
                                .catch((error) => console.log(error))
                                .finally(() => {
                                    closeRequest(requestId)
                                    window.location.href = `${window.location.origin}${window.location.pathname}`
                                })
                            }

                            break
                        case "google.com":
                            break
                        default:
                            break
                    }
                }

                const isSignInCurrently = await validateAccessToken()

                if (isSignIn) {
                    if (isSignInCurrently) {
                        // in signIn status continuously
                    } else {
                        // in case that used to sign in, but the access token has been expired
                        Promise.resolve()
                            .then(() => requestId = openRequest())
                            .then(() => getBearerToken())
                            .then(async (token) => {
                                if (token.accessToken) {
                                    // authInfo.azureInfo.accessToken has been updated successfully
                                    console.log("authInfo.azureInfo.accessToken has been updated successfully")
                                } else {
                                    // isSignIn should be switched to false
                                    console.log(`isSignIn: ${isSignIn} (in here, it should be === false)`)
                                    // ask the user to login again
                                    onLoginRequestDialogOpen()
                                }
                            })
                            .catch((error) => console.log(error))
                            .finally(() => closeRequest(requestId))
                    }
                } else {
                    // this code should run when a user reloads/reopens the page
                    // because of isSignIn === false by const [isSignIn, setIsSignIn] = useState(false) in authContent.js
                                
                    if (isSignInCurrently) {
                        switchToSignIn()
                    } else if (await wasSignIn()) {
                        Promise.resolve()
                            .then(() => requestId = openRequest())
                            .then(() => getBearerToken())
                            .then(async (token) => {
                                if (token.accessToken) {
                                    Promise.resolve()
                                        .then(() => switchToSignIn())
                                        .then(() => retrieveUser())
                                        .then(async () => {
                                            await getWordStatus()
                                            await getAvatarImage()
                                        })
                                        .catch((error) => console.log(error))
                                } else {
                                    // in notSignIn status continuously
                                }
                            })
                            .catch((error) => console.log(error))
                            .finally(() => closeRequest(requestId))
                    }
                }
            })()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSignIn, userAgentInfo, window.location.search])

    //////////////////////////////////////////////////////
    // for subapp launcher

    const [launchSubApp, setLaunchSubApp] = useState(null)

    //////////////////////////////////////////////////////

    //////////////////////////////////////////////////////

    const { 
        isOpen: isSpinnerOpen,
        onOpen: onSpinnerOpen,
        onClose: onSpinnerClose
    } = useDisclosure()

    const { 
        isOpen: isErrorDialogOpen,
        onOpen: onErrorDialogOpen,
        onClose: onErrorDialogClose
    } = useDisclosure()        

    const { 
        isOpen: isLicenseAgreementDialogOpen,
        onOpen: onLicenseAgreementDialogOpen,
        onClose: onLicenseAgreementDialogClose
    } = useDisclosure()
    
    const { 
        isOpen: isLoginRequestDialogOpen,
        onOpen: onLoginRequestDialogOpen,
        onClose: onLoginRequestDialogClose
    } = useDisclosure()

    useEffect(() => {
        if (errorMessage.title) {
            onErrorDialogOpen()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorMessage])

    //////////////////////////////////////////////////////

    // const routes = useMemo(() => (
    return (
		<Flex
            w="100vw"
            h="100vh"
            justifyContent="center"
        >
            <Flex
                direction="column"
                maxWidth="810px"
                width="100%"
            >
                <NavigationBar 
                    setLaunchSubApp={setLaunchSubApp}
                    onLoginRequestDialogOpen={onLoginRequestDialogOpen}
                />
                <Box
                    flex="1"
                    width="100%"
                >
                    <ErrorDialog
                        isOpen={isErrorDialogOpen}
                        onClose={onErrorDialogClose}
                    />

                    <FullCoverSpinner
                        isOpen={isSpinnerOpen}
                        onOpen={onSpinnerOpen}
                        onClose={onSpinnerClose}
                    />

                    <LicenseAgreementDialog
                        isOpen={isLicenseAgreementDialogOpen}
                        onOpen={onLicenseAgreementDialogOpen}
                        onClose={onLicenseAgreementDialogClose}

                        onLoginRequestDialogOpen={onLoginRequestDialogOpen}
                    />

                    <LoginRequestDialog
                        isOpen={isLoginRequestDialogOpen}
                        onClose={onLoginRequestDialogClose}
                    />

                    {
                        !userAgentInfo.isSupported ? (
                            <Box
                                width="100%"
                                height="100%"
                            >
                            {
                                userAgentInfo.browserName === "unknown" ? (
                                    <div>英活はお使いのブラウザをサポートしていません。別のブラウザでご利用ください</div>
                                ): (
                                    ["ie"].includes(userAgentInfo.browserName) ? (
                                        <div>英活は {userAgentInfo.browserName} ブラウザをサポートしていません。<br/>別のブラウザでご利用ください</div>
                                        ) : (
                                            <>
                                                <Center>英活は {userAgentInfo.browserName} ブラウザのバージョン {userAgentInfo.browserVersion} をサポートしていません。</Center>
                                                <Center>ブラウザを最新バージョンにアップデートするか、<br/>もしくは別のブラウザでご利用ください。</Center>
                                            </>
                                        )
                                )
                            }
                            </Box>
                        ) : (
                            <RoutesSettings
                                isSignIn={isSignIn}
                                setLaunchSubApp={setLaunchSubApp}
                                launchSubApp={launchSubApp}
                                onLoginRequestDialogOpen={onLoginRequestDialogOpen}
                                onLicenseAgreementDialogOpen={onLicenseAgreementDialogOpen}
                            />
                        )
                    }
                </Box>
                <Footer />
            </Flex>
		</Flex>
    )
	// eslint-disable-next-line react-hooks/exhaustive-deps
	// ), [isSignIn, userAgentInfo])

    // return (
    //     <>
    //         {routes}
    //     </>
    // )

};

function App() {
	return (
		<ChakraProvider>
        	<MainContent />
		</ChakraProvider>
	);
};

export default App;
