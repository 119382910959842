import React from "react";
import {
    Box,
    HStack,
    Spacer,
    Text,
    Radio
} from "@chakra-ui/react";
import {
    AddIcon,
    EditIcon,
    DeleteIcon,
    CheckIcon
} from '@chakra-ui/icons'
// import '@splidejs/react-splide/css';

import "../../App.css";
import {
    posEtoJShort,
    getElementIdByIndex,
    countMeanings,
    meaningTagsEToJ,
    getValueFromWord
} from "../../WordDataFunction"

import useApi from "../../hooks/useApi";
import SentenceList from "./SentenceList";
import EmbeddedLink from "../EmbeddedLink";


const MeaningList = (props) => {

    ///////////////////////////////////////////////////
    // for clicking a link
    const {
        readWordByWordId
    } = useApi()    

    const linkClickHandler = async (wordId) => {
        const wordData = await readWordByWordId(wordId)

        if (wordData) {
            props.dispatch({
                type: "clickLink",
                data: {
                    previous: {},
                    current: {
                        wordId,
                        displayName: getValueFromWord(wordData, "displayName")
                    }
                }
            })
        }        
    }
    ///////////////////////////////////////////////////

    const meaningList = []
    const isMeaningSelectable = !props.displayWordParams.hasValidSelectedMeaningId
        || (props.displayWordParams.mode === "readyToEditWordElement"
            && countMeanings(props.displayWordParams.wordData, props.posIndex) > 1)

    props.meanings.forEach((meaningNode, meaningIndex) => {
        let idMap = getElementIdByIndex(
            props.displayWordParams.wordData,
            "meaning",
            props.posIndex,
            meaningIndex)
        const posJShort = `${posEtoJShort(props.pos, meaningIndex + 1)}`
        
        meaningList.push(
            <Box
                key={`${props.posIndex}_${meaningIndex}`}
                border="1px"
                borderColor="blue.100"
                p="2px"
                m="2px 2px 24px 2px"
            >
                <HStack
                    align="start"
                >
                    {
                        meaningNode.meaningId === props.displayWordParams.selectedMeaningId ?
                            <CheckIcon 
                                color="blue.500"
                                style={{ width: '24px', height: '24px' }}
                            />
                            :
                            <></>
                    }
                    <Box
                        bgColor="blue.500"
                        textColor="white"
                        textAlign="center"
                        minWidth="42px"
                        maxWidth="42px"
                        mt="4px"
                    >
                        {`${posJShort}`}
                    </Box>
                    <Box
                        fontSize="xl"
                        pl="2px"
                    >
                        <HStack>
                            {
                                // tags
                                "tags" in meaningNode
                                        && Array.isArray(meaningNode.tags)
                                        && meaningNode.tags.length > 0 ?
                                    meaningNode.tags.map((tag, index) => {
                                        return (
                                            <Box
                                                bgColor="gray.500"
                                                textColor="white"
                                                textAlign="center"
                                                width="18px"
                                                height="18px"
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                fontSize="md"
                                                pb="2px"
                                                key={index}
                                            >
                                                {meaningTagsEToJ(tag)}
                                            </Box>
                                        )
                                    })
                                    :
                                    <></>
                            }
                            <Box>
                                <EmbeddedLink
                                    sourceText={meaningNode.meaning}
                                    needLink={true}
                                    clickHandler={linkClickHandler}
                                />
                            </Box>
                        </HStack>
                        {
                            isMeaningSelectable ?
                                <Box
                                    borderBottom="2px"
                                    borderColor="red.500"
                                    display="flex"
                                    alignItems="center"
                                    p="0px 8px 0px 4px"
                                    width="fit-content"
                                >
                                    <Radio 
                                        value={meaningNode.meaningId}
                                        colorScheme="red"
                                    >
                                        <Text as="span" color="red.500">この意味で記憶する</Text>
                                    </Radio>
                                </Box>
                                :
                                <></>
                        }

                    </Box>
                    <Spacer/>
                    {
                        props.displayWordParams.mode === "readyToEditWordElement" ?
                            <>
                                <AddIcon
                                    onClick={() => {
                                        if (!props.displayWordParams.exceedMaxUserMeaningCount) {
                                            props.dispatch({
                                                type: "displayWordEditDialogOpened",
                                                data: {
                                                    previous:{
                                                        search: props.searchParams
                                                    },
                                                    current: {
                                                        wordId: props.displayWordParams.wordId,
                                                        editDialogParams: {
                                                            type: "meaningAdd",
                                                            header: `新しい意味を追加します`,
                                                            displayName: props.displayWordParams.displayName,
                                                            ipa: props.ipa,
                                                            pos: props.pos,
                                                            inusedPoses: props.displayWordParams.inusedPoses,
                                                            meaning: "",
                                                            sentence: "",
                                                            sentenceJa: "",                                                        
                                                            idMap: {
                                                                pos: props.pos,
                                                                meaningId: crypto.randomUUID(),
                                                                sentenceId: crypto.randomUUID()
                                                            },
                                                            isOpen: true
                                                        },
                                                        isEditted: props.displayWordParams.isEditted
                                                    }
                                                }
                                            })
                                        }
                                    }}
                                    color={
                                        props.displayWordParams.exceedMaxUserMeaningCount ?
                                            "gray.300"
                                            :
                                            "black"
                                    }
                                />
                                {
                                    meaningNode.origin === "user" ?
                                        <>
                                            <EditIcon
                                                onClick={() => {
                                                    props.dispatch({
                                                        type: "displayWordEditDialogOpened",
                                                        data: {
                                                            previous:{
                                                                search: props.searchParams
                                                            },
                                                            current: {
                                                                wordId: props.displayWordParams.wordId,
                                                                editDialogParams: {
                                                                    type: "meaningEdit",
                                                                    header: `[${posJShort}] の意味を変更します`,
                                                                    displayName: props.displayWordParams.displayName,
                                                                    ipa: props.ipa,
                                                                    pos: props.pos,
                                                                    inusedPoses: props.displayWordParams.inusedPoses,
                                                                    meaning: meaningNode.meaning,
                                                                    idMap,
                                                                    isOpen: true
                                                                },
                                                                isEditted: props.displayWordParams.isEditted
                                                            }
                                                        }
                                                    })
                                                }}
                                            />
                                            {
                                                props.meanings.length > 1 ?
                                                    <DeleteIcon
                                                        onClick={() => {
                                                            props.dispatch({
                                                                type: "displayWordEditDialogOpened",
                                                                data: {
                                                                    previous:{
                                                                        search: props.searchParams
                                                                    },
                                                                    current: {
                                                                        wordId: props.displayWordParams.wordId,
                                                                        editDialogParams: {
                                                                            type: "meaningDelete",
                                                                            header: `意味：[${posJShort}] を削除します。この意味に設定されたすべての情報が削除されます。※削除すると復元することはできません`,
                                                                            meaning: meaningNode.meaning,
                                                                            idMap,
                                                                            isOpen: true
                                                                        },
                                                                        isEditted: props.displayWordParams.isEditted
                                                                    }
                                                                }
                                                            })
                                                        }}                                                
                                                    />
                                                    :
                                                    <></>
                                            }
                                        </>
                                        :
                                        <></>
                                }
                            </>
                            :
                            <></>
                        }
                </HStack>
                {
                    meaningNode.linkOnly ?
                        <></>
                        :
                        <SentenceList
                            displayWordParams={props.displayWordParams}
                            searchParams={props.searchParams}                    
                            pos={props.pos}
                            posIndex={props.posIndex}
                            ipa={props.ipa}
                            meaningNode={meaningNode}
                            meaningIndex={meaningIndex}
                            idMap={idMap}
                            sentences={meaningNode.sentences}
                            imageRepo={props.imageRepo}
                            userSentenceImages={props.userSentenceImages}
                            setUserSentenceImages={props.setUserSentenceImages}
                            dispatch={props.dispatch}
                        />
                }
            </Box>
        )
    })

    return (
        <>
            {meaningList}
        </>
    )
}

export default MeaningList