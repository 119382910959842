import {
    getValueFromWord,
    validateSelectedMeaningId,
    getInusePoses,
    countUserMeanings,
    countUserSentences,
    enumerageMeaningIds,
    traverseUserSentencesWithImage,
    getPoses
} from "../../WordDataFunction"
import {
    getSases
} from "../../CommonFunction"

// this aggregates all props to one for <DisplayWord/>

// 'mode' can have one of followings 
//     1. "registerNewUserWord"
//     2. "readyToEditWordElement"
//     3. "editWordElement"
//         requires 'editDialogPrams' parameter
//         editDialogParam also has to include 'meaningIndex' and 'sentenceIndex'
//     4. "browseWord"
// 'isEditted' means the word has been editted by the user, but not saved yet.

export function buildDisplayWordProps(
        wordData,
        editDialogParams,
        mode,
        isEditted,
        userId) {

    const wordId = getValueFromWord(wordData, "wordId")
    const cId = getValueFromWord(wordData, "cId")
    const displayName = getValueFromWord(wordData, "displayName")
    const inusedPoses = getInusePoses(wordData)
    const episode = getValueFromWord(wordData, "episode")
    const notes = getValueFromWord(wordData, "notes")
    const selectedMeaning = getValueFromWord(wordData, "selectedMeaning")
    const selectedMeaningId = getValueFromWord(wordData, "selectedMeaningId")
    const sases = getSases()
    const imageUrl = getValueFromWord(wordData, "episodeImage")

    // 'isNew' shows if the word is registered into the users DB or not
    const isNew = getValueFromWord(wordData, "isNew")
    const hasValidSelectedMeaningId = validateSelectedMeaningId(wordData)
    // 'isUserOriginWord' uses for WordTag
    const isUserOriginWord = getValueFromWord(wordData, "wordOrigin") === "user"

    const maxUserMeaningCount = 6
    const maxUserSentenceCount = 6

    const exceedMaxUserPosCount = getPoses().length > inusedPoses.length
    const exceedMaxUserMeaningCount = countUserMeanings(wordData) >= maxUserMeaningCount
    const exceedMaxUserSentenceCount = {}

    enumerageMeaningIds(wordData).forEach((meaningId) => {
        exceedMaxUserSentenceCount[meaningId] = (countUserSentences(wordData, meaningId) >= maxUserSentenceCount) 
    })
    
    const sentenceIdsHavingImage = traverseUserSentencesWithImage(wordData)

    const isRedirect = getValueFromWord(wordData, "isRedirect")
    const originalDisplayName = getValueFromWord(wordData, "originalDisplayName")

    

    return {
        wordData,
        wordId,
        cId,
        displayName,
        inusedPoses,
        episode,
        notes,
        selectedMeaning,
        selectedMeaningId,
        imageUrl,
        /////////////////////
        isNew,
        hasValidSelectedMeaningId,
        isUserOriginWord,
        /////////////////////
        editDialogParams,
        mode,
        isEditted, 
        userId,
        sases,
        /////////////////////
        exceedMaxUserPosCount,
        exceedMaxUserMeaningCount,
        exceedMaxUserSentenceCount,
        /////////////////////
        sentenceIdsHavingImage,
        /////////////////////
        // for redirect
        isRedirect,
        originalDisplayName
    }    
}