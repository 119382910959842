import {
    getMediaUrl,
    getSases
} from "./CommonFunction"


export function getPosesLength(word) {
    if (word && "poses" in word && Array.isArray(word.poses)) {
        return word.poses.length    
    } else {
        return 0
    }
}

export function getPosNode(word, posIndex) {
    if (0 <= posIndex && posIndex < getPosesLength(word)) {
        return word.poses[posIndex]
    } else {
        return null
    }
}

function getPosNodeByPos(word, pos) {
    for (let pIndex = 0; pIndex < getPosesLength(word); ++pIndex) {
        const posNode = word.poses[pIndex]
        if (posNode && "pos" in posNode && posNode.pos === pos) {
            return posNode
        }
    }
    return null
}

export function getMeaningsLength(word, posIndex) {
    const posNode = getPosNode(word, posIndex)
    if (posNode
            && "meanings" in posNode
            && Array.isArray(posNode.meanings)) {
        return posNode.meanings.length
    } else {
        return 0
    }
}

export function getMeaningNode(word, posIndex, meaningIndex) {
    const posNode = getPosNode(word, posIndex)
    if (posNode
            && 0 <= meaningIndex
            && meaningIndex < getMeaningsLength(word, posIndex)) {
        return posNode.meanings[meaningIndex]
    } else {
        return null
    }
}

export function getMeaningNodeByMeaningId(word, pos, meaningId) {
    const posNode = getPosNodeByPos(word, pos)
    if (posNode 
            && "meanings" in posNode
            && Array.isArray(posNode.meanings)) {
        for (let mIndex = 0; mIndex < posNode.meanings.length; ++mIndex) {
            const meaningNode = posNode.meanings[mIndex]
            if ("meaningId" in meaningNode && meaningNode.meaningId === meaningId) {
                return meaningNode
            }
        }
    }
    return null
}

export function getSentencesLength(word, posIndex, meaningIndex) {
    const meaningNode = getMeaningNode(word, posIndex, meaningIndex)
    if (meaningNode
            && "sentences" in meaningNode
            && Array.isArray(meaningNode.sentences)) {
        return meaningNode.sentences.length
    }
    return 0
}

export function getSentenceNode(word, posIndex, meaningIndex, sentenceIndex) {
    const meaningNode = getMeaningNode(word, posIndex, meaningIndex)
    if (meaningNode
            && 0 <= sentenceIndex
            && sentenceIndex < getSentencesLength(word, posIndex, meaningIndex)) {
        return meaningNode.sentences[sentenceIndex]
    } else {
        return null
    }
}

export function getSentenceNodeBySentenceId(word, pos, meaningId, sentenceId) {
    const meaningNode = getMeaningNodeByMeaningId(word, pos, meaningId)
    if (meaningNode 
            && "sentencess" in meaningNode
            && Array.isArray(meaningNode.sentences)) {
        for (let sIndex = 0; sIndex < meaningNode.sentences.length; ++sIndex) {
            const sentenceNode = meaningNode.sentences[sIndex]
            if ("sentenceId" in sentenceNode && sentenceNode.sentenceId === sentenceId) {
                return sentenceNode
            }
        }
    }
    return null
}

export function enumeratePosNodes(word) {
    if (word && "poses" in word && Array.isArray(word.poses)) {
        return word.poses
    } else {
        return []
    }
}

export function enumerateMeaningNodes(posNode) {
    if (posNode && "meanings" in posNode && Array.isArray(posNode.meanings)) {
        return posNode.meanings
    } else {
        return []
    }
}

export function enumerateSentenceNodes(meaningNode) {
    if (meaningNode && "sentences" in meaningNode && Array.isArray(meaningNode.sentences)) {
        return meaningNode.sentences
    } else {
        return []
    }
}

export function getPronunciationNode(parentNode, pronunciationIndex) {
    if (parentNode
            && parentNode?.pronunciations
            && pronunciationIndex in parentNode.pronunciations) {
        return parentNode.pronunciations[pronunciationIndex]
    } else {
        return null
    }
}

export function getElementIdByIndex(
    word,
    key,
    posIndex,
    meaningIndex,
    sentenceIndex = undefined
)
{
    let idMap = {}

    if (["pos", "meaning", "sentence"].includes(key)) {
        const posNode = getPosNode(word, posIndex)

        if (posNode) {
            idMap.pos = posNode.pos
            
            // not using getMeaningNode() because of avoiding the retraverse
            if (["meaning", "sentence"].includes(key)
                    && posNode?.meanings
                    && posNode.meanings.length > meaningIndex 
                    && meaningIndex >= 0
                    && posNode.meanings[meaningIndex]?.meaningId
            ) {
                const meaningNode = posNode.meanings[meaningIndex]
                idMap.meaningId = meaningNode.meaningId

                // not using getSentenceNode() because of avoiding the retraverse
                if (["sentence"].includes(key)
                        && meaningNode?.sentences
                        && meaningNode.sentences.length > sentenceIndex
                        && sentenceIndex >= 0
                        && meaningNode.sentences[sentenceIndex]?.sentenceId
                ) {
                    const sentenceNode = meaningNode.sentences[sentenceIndex]
                    idMap.sentenceId = sentenceNode.sentenceId
                }
            }
        } else {
            return undefined
        }
    }

    return idMap
}



export function getValueFromWord(word,
    key,
    posIndex = undefined,
    meaningIndex = undefined,
    sentenceIndex = undefined
)
{
    let posNode = undefined
    let meaningNode = undefined
    let sentenceNode = undefined

    try {
        switch (key) {
            case "isNew":
                return word?.isNew && word.isNew ? true : false
            case "wordId":
                return word.wordId
            case "displayName":
                return word.displayName
            case "stem":
                return word.stem
            case "cefr":
                return word?.cefr ? word.cefr : "B2"    // default quiz candidates come from "B2"
            case "episode":
                if (word?.episode 
                        && word.episode?.text) {
                    return word.episode.text
                } else {
                    return ""
                }
            case "episodeImage":
                if (word?.wordId
                        && word?.episode 
                        && word.episode?.hasImage) {
                    return `${getMediaUrl()}/media/image/episode/${word.wordId}.png?${getSases().mastervocabulary}`
                } else {
                    return ""
            }
            case "isStarred":
                return word?.isStarred ? word.isStarred : false
            case "notes":
                return word?.notes ? word.notes : ""
            case "wordOrigin":
                return word.origin
            case "selectedMeaningId":
                return word?.selectedMeaningId ? word.selectedMeaningId : undefined
            // case "quizHistory":
            //     return word?.quizHistory ? word.quizHistory : []
            case "hasUserOriginMeaning":
                return word?.hasUserOriginMeaning ? word.hasUserOriginMeaning : false
            case "isRedirect":
                return word?.isRedirect ? word.isRedirect : false
            case "originalDisplayName":
                return word?.originalDisplayName ? word.originalDisplayName : ""
            case "cId":
                return word?.cId ? word.cId : ""
            /////////////////////////
            // under word.poses[]
            case "pos":
                posNode = getPosNode(word, posIndex)
                return posNode && posNode?.pos ? posNode.pos : ""
            case "posWordAudio":
                const urls = []
                posNode = getPosNode(word, posIndex)
                if (posNode) {
                    const pronunciationNode = getPronunciationNode(word, posNode.pronunciationIndex)
                    if (pronunciationNode 
                            && "audio" in pronunciationNode) {
                        pronunciationNode["audio"].forEach((path) => {
                            urls.push(`${getMediaUrl()}/media/audio/word/${path}${word.wordId}_${posNode.pronunciationIndex}.mp3?${getSases().mastervocabulary}`)
                        })
                    }
                }
                return urls
            case "posWordIpa": {
                posNode = getPosNode(word, posIndex)
                if (posNode) {
                    const pronunciationNode = getPronunciationNode(word, posNode.pronunciationIndex)
                    if (pronunciationNode 
                            && "ipa" in pronunciationNode) {
                        return pronunciationNode["ipa"]
                    }
                }
                return null
            }
            case "posAdditionalInfo":
                posNode = getPosNode(word, posIndex)
                return posNode && posNode?.additionalInfo ? posNode.additionalInfo : {}
            case "posInflections":
                posNode = getPosNode(word, posIndex)
                return posNode && posNode?.inflections ? posNode.inflections : {}
            case "posCountability":
                posNode = getPosNode(word, posIndex)
                return posNode && posNode?.countability ? posNode.countability : {}
            case "posOrigin":
                posNode = getPosNode(word, posIndex)
                return posNode ? posNode.origin : null

            /////////////////////////
            // under word.poses[].meanings[]
            case "meaningId":
                meaningNode = getMeaningNode(word, posIndex, meaningIndex)
                return meaningNode ? meaningNode.meaningId : ""
            case "meaning":
                meaningNode = getMeaningNode(word, posIndex, meaningIndex)
                return meaningNode ? meaningNode.meaning : ""
            case "meaningLinkOnly":
                meaningNode = getMeaningNode(word, posIndex, meaningIndex)
                return meaningNode ? meaningNode.linkOnly : false
            case "meaningTags":
                meaningNode = getMeaningNode(word, posIndex, meaningIndex)
                return meaningNode ? meaningNode.tags : []
            case "meaningOrigin":
                meaningNode = getMeaningNode(word, posIndex, meaningIndex)
                return meaningNode ? meaningNode.origin : null
            
            /////////////////////////
            // under word.poses[].meanings[].sentences[]
            case "sentenceId":
                sentenceNode = getSentenceNode(word, posIndex, meaningIndex, sentenceIndex)
                return sentenceNode ? sentenceNode.sentenceId : ""
            case "sentence":
                sentenceNode = getSentenceNode(word, posIndex, meaningIndex, sentenceIndex)
                return sentenceNode ? sentenceNode.sentence : ""
            case "sentenceJa":
                sentenceNode = getSentenceNode(word, posIndex, meaningIndex, sentenceIndex)
                return sentenceNode ? sentenceNode.sentenceJa : ""
            case "sentenceAudio":
                sentenceNode = getSentenceNode(word, posIndex, meaningIndex, sentenceIndex)
                if (sentenceNode) {
                    const pronunciationIndex = "0"
                    const pronunciationNode = getPronunciationNode(sentenceNode, pronunciationIndex)
                    if (pronunciationNode
                            && "audio" in pronunciationNode
                            && pronunciationNode["audio"].length > 0) {
                        return `${getMediaUrl()}/media/audio/sentence/${pronunciationNode["audio"][0]}${sentenceNode.sentenceId}_${pronunciationIndex}.mp3?${getSases().mastervocabulary}`
                    }
                }
                return ""
            case "sentenceImage":
                sentenceNode = getSentenceNode(word, posIndex, meaningIndex, sentenceIndex)
                if (sentenceNode 
                        && "hasImage" in sentenceNode
                        && sentenceNode.hasImage) {
                    return `${getMediaUrl()}/media/image/sentence/${sentenceNode.sentenceId}.png?${getSases().mastervocabulary}`
                }
                return ""
            case "fitbSentence":
                sentenceNode = getSentenceNode(word, posIndex, meaningIndex, sentenceIndex)
                return sentenceNode && "fitbSentence" in sentenceNode ? sentenceNode.fitbSentence : ""
            case "fitb":
                sentenceNode = getSentenceNode(word, posIndex, meaningIndex, sentenceIndex)
                return sentenceNode && "fitb" in sentenceNode ? sentenceNode.fitb : ""
            case "fitbInflection":
                sentenceNode = getSentenceNode(word, posIndex, meaningIndex, sentenceIndex)
                return sentenceNode && "fitbInflection" in sentenceNode ? sentenceNode.fitbInflection : ""
            case "sentenceOrigin":
                sentenceNode = getSentenceNode(word, posIndex, meaningIndex, sentenceIndex)
                return sentenceNode ? sentenceNode.origin : null

            /////////////////////////
            // under word.quiz
            case "quizMeaningCandidates":
                if (word?.quiz
                        && "meaningCandidates" in word.quiz) {
                    return word.quiz.meaningCandidates
                } else {
                    return undefined
                }
            case "quizFitbCandidates":
                if (word?.quiz
                        && "fitbCandidates" in word.quiz) {
                    return word.quiz.fitbCandidates
                } else {
                    return undefined
                }

            /////////////////////////
            // special keys
            case "selectedMeaning":
                const selectedMeaningSentence = chooseSelectedMeaningSentence(word)
                if (selectedMeaningSentence) {
                    return getValueFromWord(word, "meaning", selectedMeaningSentence.selectedPosIndex, selectedMeaningSentence.meaningIndex)
                } else {
                    return null
                }
            
            default:
                throw new Error(`key:${key} is not defined`)
        }
        
    } catch (error) {
            console.log(`error:${error}, key: ${key}, meaningIndex: ${meaningIndex}, sentenceIndex: ${sentenceIndex}`)
            // console.log(`word:${JSON.stringify(word)}`)
    }

    return undefined
}

export function getIndexesById(word, pos, meaningId, sentenceId=undefined) {
    let posIndex = undefined
    let meaningIndex = undefined
    let sentenceIndex = undefined

    word.poses.some((p, index) => {
        if (p.pos === pos) {
            posIndex = index
            return true
        } else {
            return false
        }
    })

    if (posIndex !== undefined) {
        const posNode = word.poses[posIndex]
        if (posNode?.meanings) {
            posNode.meanings.some((meaning, index) => {
                if (meaning.meaningId === meaningId) {
                    meaningIndex = index
                    return true
                } else {
                    return false
                }
            })

            if (meaningIndex !== undefined && sentenceId !== undefined) {
                const meaningNode = posNode.meanings[meaningIndex]
                if (meaningNode?.sentences) {
                    meaningNode.sentences.some((sentence, index) => {
                        if (sentence.sentenceId === sentenceId) {
                            sentenceIndex = index
                            return true
                        } else {
                            return false
                        }
                    })
                }
            }
        }
    }

    return [posIndex, meaningIndex, sentenceIndex]
}

export function getValueFromWordById(word, key, idMap) {
    const [posIndex, meaningIndex, sentenceIndex] = getIndexesById(word, idMap.pos, idMap.meaningId, idMap?.sentenceId)
    return getValueFromWord(word,
        key,
        posIndex,
        meaningIndex,
        sentenceIndex
    )
}

export function setValueToWord(word,
    key,
    value,
    posIndex = undefined,
    meaningIndex = undefined,
    sentenceIndex = undefined
)
{
    let posNode = undefined
    let meaningNode = undefined
    let sentenceNode = undefined

    if (Object.keys(word).length === 0) {
        return undefined
    } else {
        switch (key) {
            // case "wordId":
            // case "stem":
            // case "displayName":
            // case "pronunciation":
            case "pos":
                posNode = getPosNode(word, posIndex)
                if (posNode) {
                    posNode.pos = value
                    return word
                }
                return undefined
            case "meaning":
                meaningNode = getMeaningNode(word, posIndex, meaningIndex)
                if (meaningNode) {
                    meaningNode.meaning = value
                    return word
                }
                return undefined
            case "sentence":
                sentenceNode = getSentenceNode(word, posIndex, meaningIndex, sentenceIndex)
                if (sentenceNode) {
                    sentenceNode.sentence = value
                    return word
                }
                return undefined
            case "sentenceJa":
                sentenceNode = getSentenceNode(word, posIndex, meaningIndex, sentenceIndex)
                if (sentenceNode) {
                    sentenceNode.sentenceJa = value
                    return word
                }
                return undefined
            case "sentenceHasImage":
                sentenceNode = getSentenceNode(word, posIndex, meaningIndex, sentenceIndex)
                if (sentenceNode) {
                    sentenceNode.hasImage = value
                    return word
                }
                return undefined
            case "notes":
                word.notes = value
                return word
            case "selectedMeaningId":
                word.selectedMeaningId = value
                return word
            case "hasUserOriginMeaning":
                word.hasUserOriginMeaning = value
                return word
            case "ipa":
                if (!("pronunciations" in word)) {
                    word.pronunciations = {}
                }
                const pronunciationIndex = String(Object.keys(word.pronunciations).length)
                word.pronunciations[pronunciationIndex] = {"ipa": value}
                posNode = getPosNode(word, posIndex)
                if (posNode) {
                    posNode.pronunciationIndex = pronunciationIndex
                    return word
                }
                return undefined
            default:
                throw Error(`key:${key} is not defined`)
        }    
    }
}

export function setValueToWordById(word,
    key,
    value,
    idMap
) {
    let posIndex = undefined
    let meaningIndex = undefined
    let sentenceIndex = undefined

    const elementKey = ["notes", "selectedMeaningId", "hasUserOriginMeaning"].includes(key) ?
        "root"
        :
        ["pos", "ipa"].includes(key) ?
            "pos"
            :
            ["meaning"].includes(key) ?
                "meaning"
                :
                ["sentence", "sentenceJa", "sentenceHasImage"].includes(key) ?
                    "sentence"
                    :
                    undefined

    if (elementKey === undefined) {
        throw Error(`key:${key} is not defined.`)
    }

    let match = false
    if (elementKey && word) {
        if (elementKey === "root") {
            match = true
        } else {
            for (let pIndex = 0; pIndex < getPosesLength(word); ++pIndex) {
                const posNode = word.poses[pIndex]
                if (word.poses.length === 1 && !posNode.pos) {
                    posNode.pos = idMap.pos
                }
                if (posNode.pos === idMap.pos) {
                    posIndex = pIndex
                    if (elementKey === "pos") {
                        match = true
                        break
                    } else if (posNode?.meanings) {
                        for (let mIndex = 0; mIndex < posNode.meanings.length; ++mIndex) {
                            const meaningNode = posNode.meanings[mIndex]
                            if (meaningNode.meaningId === idMap.meaningId) {
                                meaningIndex = mIndex
                                if (elementKey === "meaning") {
                                    match = true
                                    break
                                } else if (meaningNode?.sentences) {
                                    for (let sIndex = 0; sIndex < meaningNode.sentences.length; ++sIndex) {
                                        const sentenceNode = meaningNode.sentences[sIndex]
                                        if (sentenceNode.sentenceId === idMap.sentenceId) {
                                            sentenceIndex = sIndex
                                            match = true
                                            break
                                        }
                                    }
                                }
                            }
                            if (match) {
                                break
                            }
                        }
                    }
                }
                if (match) {
                    break
                }
            }
        }
    }

    if (match) {
        return setValueToWord(word, key, value, posIndex, meaningIndex, sentenceIndex)
    } else {
        return null
    }
}

export function removeElementFromWord(word, key, idMap)
{
    if (!(["pos", "meaning", "sentence"].includes(key))) {
        return null
    }

    const posNodes = enumeratePosNodes(word)
    for (let pIndex = 0; pIndex < posNodes.length; ++pIndex) {
        const posNode = posNodes[pIndex]
        if ("pos" in posNode && posNode.pos === idMap.pos) {
            if (key === "pos") {
                word.poses.splice(pIndex, 1)
                return word
            } else if ("meaningId" in idMap) {
                const meaningNodes = enumerateMeaningNodes(posNode)
                for (let mIndex = 0; mIndex < meaningNodes.length; ++mIndex) {
                    const meaningNode = meaningNodes[mIndex]
                    if ("meaningId" in meaningNode && meaningNode.meaningId === idMap.meaningId) {
                        if (key === "meaning") {
                            word.poses[pIndex].meanings.splice(mIndex, 1)
                            return word
                        } else if ("sentenceId" in idMap) {
                            const sentenceNodes = enumerateSentenceNodes(meaningNode)
                            for (let sIndex = 0; sIndex < sentenceNodes.length; ++sIndex) {
                                const sentenceNode = sentenceNodes[sIndex]
                                if ("sentenceId" in sentenceNode && sentenceNode.sentenceId === "sentence") {
                                    word.poses[pIndex].meanings[mIndex].sentences.splice(sIndex, 1)
                                    return word
                                }
                            }
                            return null
                        }
                    }
                }
                return null
            }
        }
    }
    return null
}

export function appendElementToWord(word, key, idMap) {

    if (!["pos", "meaning", "sentence"].includes(key)) {
        return null
    }

    const sentenceTemplate = {
        sentenceId: idMap?.sentenceId,
        origin: "user",
        sentence: "",
        sentenceJa: "",
        pronunciations: {},
        hasImage: false,
        fitbSentence: "",
        fitb: "",
        fitbInflection: ""
    }
    const meaningTemplate = {
        meaningId: idMap?.meaningId,
        origin: "user",
        meaning: "",
        linkOnly: false,
        tags: [],
        sentences: [sentenceTemplate]
    }
    const posTemplate = {
        pos: idMap?.pos,
        origin: "user",
        pronunciationIndex: "",
        additionalInfo: {},
        inflections: {},
        countability: {},
        meanings: [meaningTemplate]
    }

    if (idMap?.pos) {
        const posNodes = enumeratePosNodes(word)
        for (let pIndex = 0; pIndex < posNodes.length; ++pIndex) {
            const posNode = posNodes[pIndex]
            if (posNode?.pos && posNode.pos === idMap.pos) {
                if (key === "pos") {
                    console.log(`pos:${idMap.pos} cannot be added. It has already exist`)
                    return null
                } else if (idMap?.meaningId) {
                    const meaningNodes = enumerateMeaningNodes(posNode)
                    for (let mIndex = 0; mIndex < meaningNodes.length; ++mIndex) {
                        const meaningNode = meaningNodes[mIndex]
                        if (meaningNode?.meaningId && meaningNode.meaningId === idMap.meaningId) {
                            if (key === "meaning") {
                                console.log(`meaningId:${idMap.meaningId} cannot be added. It has already exist`)
                                return null
                            } else if (idMap?.sentenceId) {
                                const sentenceNodes = enumerateSentenceNodes(meaningNode)
                                for (let sIndex = 0; sIndex < sentenceNodes.length; ++sIndex) {
                                    const sentenceNode = sentenceNodes[sIndex]
                                    if (sentenceNode?.sentenceId && sentenceNode.sentenceId === idMap.sentenceId) {
                                        if (key === "sentence") {
                                            console.log(`meaningId:${idMap.meaningId} cannot be added. It has already exist`)
                                            return null
                                        }
                                        else {
                                            console.log(`key:${key} is invalid`)
                                            return null
                                        }
                                    }                                    
                                }
                                if (key === "sentence" && "sentences" in meaningNode && Array.isArray(meaningNode.sentences)) {
                                    meaningNode.sentences.push(sentenceTemplate)
                                    return word
                                }
                            }
                        }
                    }
                    if (key === "meaning" && "meanings" in posNode && Array.isArray(posNode.meanings)) {
                        posNode.meanings.push(meaningTemplate)
                        return word
                    }
                }
            }
        }
        if (key === "pos" && "poses" in word && Array.isArray(word.poses)) {
            word.poses.push(posTemplate)
            return word
        }
    }
    
    return null
}

export function getEpisodeImageByWordId(wordId) {
    return `${getMediaUrl()}/media/image/episode/${wordId}.png?${getSases().mastervocabulary}`
}

export function getWordImage(imageFile) {
    return `${getMediaUrl()}/media/image/${imageFile}?${getSases().mastervocabulary}`
}

export function traverseUserSentencesWithImage(
    word,
    idMap=null
)
{
    const sentenceIds = []

    if (word && word?.poses) {
        word.poses.forEach((pos) => {
            if (idMap === null || ("pos" in idMap && pos.pos === idMap.pos)) {
                if (pos?.meanings) {
                    pos.meanings.forEach((meaning) => {
                        if (idMap === null || ("meaningId" in idMap && meaning.meaningId === idMap.meaningId)) {
                            if (meaning?.sentences) {
                                meaning.sentences.forEach((sentence) => {
                                    if (idMap === null || !("sentenceId" in idMap) || ("sentenceId" in idMap && sentence.sentenceId === idMap.sentenceId)) {
                                        if (sentence?.origin === "user" && sentence?.hasImage) {
                                            sentenceIds.push(sentence.sentenceId)
                                        }
                                    }
                                })
                            }
                        }
                    })
                }
            }
        })
    }

    return sentenceIds
}

export function countMeanings(word, posIndex) {
    const posNode = getPosNode(word, posIndex)

    if (posNode && posNode.meanings) {
        return posNode.meanings.length
    } else {
        return -1
    }
}

const poses = {
    "Noun": "名詞",
    "Verb": "動詞",
    "Adjective": "形容詞",
    "Adverb": "副詞",
    "Proper noun": "固有名詞",
    "Interjection": "間投詞",
    "Prepositional phrase": "前置詞句",
    "Phrase": "句",
    "Proverb": "ことわざ",
    "Preposition": "前置詞",
    "Numeral": "数詞",
    "Pronoun": "代名詞",
    "Conjunction": "接続詞",
    "Determiner": "限定詞",
    "Contraction": "短縮形",
    "Auxiliary verb": "助動詞",
    "Others": "その他"
}

export function getPoses() {
    return poses
}

export function posEtoJ(pos) {
    if (pos in poses) {
        return poses[pos]
    } else {
        return " - "
    }
}

export function posEtoJShort(pos, digit) {
    const jPos = posEtoJ(pos)
    if (pos === "Other") {
        return `${jPos.substr(jPos.length - 1, 1)}${digit}`
    } else {
        return `${jPos.substr(0, 1)}${digit}`
    }
}

export function getDefinedPosCount() {
    return Object.keys(poses).length
}

export function getInusePoses(word, exceptPos="") {
    const inusedPoses = new Set()
    enumeratePosNodes(word).forEach((posNode) => {
        if ("pos" in posNode && posNode.pos !== exceptPos) {
            inusedPoses.add(posNode.pos)
        }
    })

    return inusedPoses
}

export function chooseSelectedMeaningSentence(word) {
    if (word?.poses && word?.selectedMeaningId) {
        let selectedPosIndex = undefined
        let selectedMeaningIndex = undefined
        let sentenceIndex = undefined
        let anotherSentenceIndex = undefined

        let match = false
        for (let pIndex = 0; pIndex < word.poses.length; ++pIndex) {
            const posNode = word.poses[pIndex]
            if (posNode?.meanings) {
                // initialize fall-back values
                selectedPosIndex = selectedMeaningIndex = undefined
                for (let mIndex = 0; mIndex < posNode.meanings.length; ++mIndex) {
                    const meaningNode = posNode.meanings[mIndex]
                    if (selectedMeaningIndex === undefined) {
                        // set fall-back values
                        selectedPosIndex = pIndex
                        selectedMeaningIndex = mIndex
                    }
                    if (meaningNode.meaningId === word.selectedMeaningId) {
                        selectedPosIndex = pIndex
                        selectedMeaningIndex = mIndex
                        match = true
                        break
                    }
                }
            }
            if (match) {
                break
            }
        }

        if (selectedPosIndex !== undefined 
                && selectedMeaningIndex !== undefined) {

            const selectedMeaningNode = word.poses[selectedPosIndex].meanings[selectedMeaningIndex]
            
            if (selectedMeaningNode?.sentences) {
                sentenceIndex = Math.floor(Math.random() * selectedMeaningNode.sentences.length)
                anotherSentenceIndex = Math.floor(Math.random() * selectedMeaningNode.sentences.length)
                if (sentenceIndex === anotherSentenceIndex) {
                    anotherSentenceIndex = (anotherSentenceIndex + 1) % selectedMeaningNode.sentences.length
                }
            }

            return {
                posIndex: selectedPosIndex,
                meaningIndex: selectedMeaningIndex, 
                sentenceIndex, 
                anotherSentenceIndex
            }
        }
    }
    
    return null
}

export function chooseSelectedMeaningSentences(words) {
    return words.map((word) => chooseSelectedMeaningSentence(word))
}

export function cleanWordData(wordData) {
    const word = {...wordData}

    let hasOriginUserPos = false

    // remove pos, meanings and sentences with origin === "system" and the empty folders
    if (word?.poses) {
        const removedPosIndexes = []
        for (let pIndex = 0; pIndex < word.poses.length; ++pIndex) {
            const posNode = word.poses[pIndex]
            if (posNode.origin === "user") {
                hasOriginUserPos = true
            }
            if (posNode?.meanings) {
                const removedMeaningIndexes = []
                for (let mIndex = 0; mIndex < posNode.meanings.length; ++mIndex) {
                    const meaningNode = posNode.meanings[mIndex]
                    if (meaningNode?.sentences) {
                        const removedSentenceIndexed = []
                        for (let sIndex = 0; sIndex < meaningNode.sentences.length; ++sIndex) {
                            const sentenceNode = meaningNode.sentences[sIndex]
                            if (sentenceNode.origin === "system") {
                                removedSentenceIndexed.unshift(sIndex)
                            }
                        }
                        removedSentenceIndexed.forEach((i) => {meaningNode.sentences.splice(i, 1)})
                        if (meaningNode.sentences.length === 0) {
                            delete meaningNode.sentences
                        }
                    }
                    if (meaningNode.origin === "system"
                            && (!meaningNode?.sentences || meaningNode.sentences.length === 0)) {
                        removedMeaningIndexes.unshift(mIndex)
                    }
                }
                removedMeaningIndexes.forEach((i) => {posNode.meanings.splice(i, 1)})
                if (posNode.meanings.length === 0) {
                    delete posNode.meanings
                }
            }
            if (posNode.origin === "system"
                    && (!posNode.meanings || posNode.meanings.length === 0)) {
                removedPosIndexes.unshift(pIndex)
            }
        }
        removedPosIndexes.forEach((i) => {word.poses.splice(i, 1)})
        if (word.poses.length === 0) {
            delete word.poses
        }
    }

    // remove invalid properties that were not defined here
    //
    // remove conditionally
    // "pronunciations" if no pos with origin === "user" (=hasOriginUserPos)
    // "notes" if notes.strip().length === 0
    // isStarred if it's false
    //
    const level0ValidProps = [
        "wordId",
        "origin",
        "displayName",
        "stem",
        "cefr", // this is required for choosing the quiz candidates
        "poses",
        "notes",
        "selectedMeaningId",
        "cId",
        "isStarred",
        "pronunciations",
        "hasUserOriginMeaning"
    ]

    // remove conditionally
    // "pronunciationIndex" if "origin" === "system"
    //
    const level1ValidProps = [
        "pos",
        "origin",
        "pronunciationIndex",
        "meanings"
    ]

    // remove conditionally
    // "meaning" if "origin" === "system"
    //
    const level2ValidProps = [
        "meaningId",
        "origin",
        "meaning",
        "sentences"
    ]

    // no conditional remove because the "origin" must be "user"
    const level3ValidProps = [
        "sentenceId",
        "origin",
        "sentence",
        "sentenceJa",
        "hasImage"
    ]

    // remove invalid keys from each levels of nodes
    const wordInvalidKeys = []
    Object.keys(word).forEach((key) => {
        if (!level0ValidProps.includes(key)) {
            wordInvalidKeys.push(key)
        } else if (key === "pronunciations" && !hasOriginUserPos) {
            wordInvalidKeys.push(key)
        } else if (key === "notes" && "notes" in word && word.notes.trim().length === 0) {
            wordInvalidKeys.push(key)
        } else if (key === "isStarred" && "isStarred" in word && !word.isStarred) {
            wordInvalidKeys.push(key)
        } else if (key === "poses" && word?.poses) {
            word.poses.forEach((posNode) => {
                const posInvalidKeys = []
                const posOrigin = posNode.origin
                Object.keys(posNode).forEach((key) => {
                    if (!level1ValidProps.includes(key)) {
                        posInvalidKeys.push(key)
                    } else if (key === "pronunciationIndex" && posOrigin === "system") {
                        posInvalidKeys.push(key)
                    } else if (key === "meanings" && posNode?.meanings) {
                        posNode.meanings.forEach((meaningNode) => {
                            const meaningInvalidKeys = []
                            const meaningOrigin = meaningNode.origin
                            Object.keys(meaningNode).forEach((key) => {
                                if (!level2ValidProps.includes(key)) {
                                    meaningInvalidKeys.push(key)
                                } else if (key === "meaning" && meaningOrigin === "system") {
                                    meaningInvalidKeys.push(key)
                                } else if (key === "sentences" && meaningNode?.sentences) {
                                    meaningNode.sentences.forEach((sentenceNode) => {
                                        const sentenceInvalidKeys = []
                                        Object.keys(sentenceNode).forEach((key) => {
                                            if (!level3ValidProps.includes(key)) {
                                                sentenceInvalidKeys.push(key)
                                            }
                                        })
                                        sentenceInvalidKeys.forEach((key) => {
                                            delete sentenceNode[key]
                                        })
                                    })
                                }
                            })
                            meaningInvalidKeys.forEach((key) => {
                                delete meaningNode[key]
                            })
                        })
                    }
                })
                posInvalidKeys.forEach((key) => {
                    delete posNode[key]
                })
            })
        }
    })
    wordInvalidKeys.forEach((key) => {
        delete word[key]
    })

    return word
}

export function permutateWordElements(word) {

    if (word && word?.poses) {
        if (word.poses.length === 1
                && word.poses[0]?.meanings
                && word.poses[0].meanings.length === 1) {
            
            const firstMeaningId = getValueFromWord(word, "meaningId", 0, 0)
            word = setValueToWord(word, "selectedMeaningId", firstMeaningId)
        
        } else {
            const selectedMeaningId = getValueFromWord(word, "selectedMeaningId")
            const posNodes = []
            let hasSelectedMeaningId = false

            for (let pIndex = 0; pIndex < word.poses.length; ++pIndex) {
                let posNode = word.poses[pIndex]
                const meaningNodes = []
                let hasMatchMeaning = false
                if (posNode?.meanings) {
                    for (let mIndex = 0; mIndex < posNode.meanings.length; ++mIndex) {
                        const meaningNode = posNode.meanings[mIndex]
                        if (meaningNode.meaningId === selectedMeaningId) {
                            meaningNodes.unshift(meaningNode)
                            hasMatchMeaning = true
                            hasSelectedMeaningId = true
                        } else {
                            meaningNodes.push(meaningNode)
                        }
                    }
                }
                posNode = {
                    ...posNode,
                    "meanings": meaningNodes
                }
                if (hasMatchMeaning) {
                    posNodes.unshift(posNode)
                } else {
                    posNodes.push(posNode)
                }
            }

            if (!hasSelectedMeaningId) {
                word = setValueToWord(word, "selectedMeaningId", "")
            }

            word = {
                ...word,
                "poses": posNodes
            }
        }
    }
    
    return word
}

export function validateSelectedMeaningId(word) {
    const selectedMeaningId = getValueFromWord(word, "selectedMeaningId")

    if (!selectedMeaningId) {
        return false
    } else if (!word?.poses) {
        return false
    } else {
        for (let pIndex = 0; pIndex < word.poses.length; ++pIndex) {
            const posNode = word.poses[pIndex]
            if (posNode?.meanings) {
                for (let mIndex = 0; mIndex < posNode.meanings.length; ++mIndex) {
                    const meaningNode = posNode.meanings[mIndex]
                    if (meaningNode.meaningId === selectedMeaningId) {
                        return true
                    }
                }
            }
        }
        return false
    }
}

export function extractUserOriginMeanings(word) {
    const meanings = []

    enumeratePosNodes(word).forEach((posNode) => {
        enumerateMeaningNodes(posNode).forEach((meaningNode) => {
            if ("origin" in meaningNode && meaningNode.origin === "user") {
                meanings.push(meaningNode.meaning)
            }
        })
    })

    // if (word && word?.poses) {
    //     word.poses.forEach((posNode) => {
    //         if (posNode?.meanings) {
    //             posNode.meanings.forEach((meaningNode) => {
    //                 if (meaningNode.origin === "user") {
    //                     meanings.push(meaningNode.meaning)
    //                 }
    //             })
    //         }
    //     })
    // }

    return meanings
}

export function countUserMeanings(word) {
    return extractUserOriginMeanings(word).length
}

export function countUserSentences(word, pos, meaningId) {
    let count = 0

    const meaningNode = getSentenceNode(word, pos, meaningId)

    if (meaningNode && meaningNode?.sentences) {
        meaningNode.sentences.forEach((sentenceNode) => {
            if (sentenceNode.origin === "user") {
                ++count
            }
        })
    }

    return count
}

export function enumerageMeaningIds(word) {
    const meaningIds = []

    if (word && word?.poses) {
        for (let pIndex = 0; pIndex < word.poses.length; ++pIndex) {
            const posNode = word.poses[pIndex]
            if (posNode?.meanings) {
                for (let mIndex = 0; mIndex < posNode.meanings.length; ++mIndex) {
                    const meaningNode = posNode.meanings[mIndex]
                    meaningIds.push(meaningNode.meaningId)
                }
            }
        }
    }

    return meaningIds
}

const meaningTags = {
    "transitive": "他",
    "intransitive": "自",
    "idiomatic": "慣",
    "US": "🇺🇸",
    "informal": "俗",
    "slang": "俗",
    "British": "🇬🇧",
    "obsolete": "古",
    "archaic": "古",
    "colloquial": "口",
    "Australia": "🇦🇺",
    "New Zealand": "🇳🇿",
    "Canada": "🇨🇦",
    "Scotland": "🏴󠁧󠁢󠁳󠁣󠁴󠁿",
    "idiom": "熟",
    "uncommon": "稀",
    "nonstandard": "稀"
}

export function meaningTagsEToJ(tag) {
    if (tag in meaningTags) {
        return meaningTags[tag]
    } else {
        return "ー"
    }

}