import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Center,
    HStack,
    Stack,
    Image,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from "@chakra-ui/react";
import { useGoogleLogin } from '@react-oauth/google';
import { loginRequest } from "../authConfig"
import { useMsal } from '@azure/msal-react'
import { ReactComponent as MicrosoftSvg } from "../assets/icons/microsoft.svg"
import { ReactComponent as GoogleSvg } from "../assets/icons/google.svg"
import LinePng from "../assets/icons/line.png"
// import { ReactComponent as FacebookSvg } from "../assets/icons/facebook.svg"
import { 
    getUserAgentInfo,
    getRandomInt
} from "../CommonFunction"
import { useAuthContext } from "../context/authContext";
import useApi from "../hooks/useApi"
import { useFullCoverSpinnerContext } from "../context/fullCoverSpinnerContext"
import useAsyncLocalStorage from "../hooks/useAsyncLocalStorage";

const LoginRequestDialog = (props) => {

    const { instance } = useMsal()

    const {
        initBearerToken,
        retrieveUser,
        getWordStatus,
        getAvatarImage

    } = useApi()

    const {
        switchToSignIn,
        getRequestType
    } = useAuthContext()

    const {
        openRequest,
        closeRequest
    } = useFullCoverSpinnerContext()

    const {
        overwriteAuthInfo
    } = useAsyncLocalStorage()

    /////////////////////////////////////////////////////
    // google login
    // useGoogleLogin version

    const googleLogin = useGoogleLogin({
        onSuccess: async codeResponse => {
            let requestId
            Promise.resolve()
                .then(() => requestId = openRequest())
                .then(() => getRequestType())
                .then((requestType) => initBearerToken(
                    requestType,
                    "google.com",
                    codeResponse.code
                ))
                .then(async (result) => {
                    if (result) {
                        await switchToSignIn()
                        await retrieveUser()
                    }
                })
                .then(async ()=> {
                    await getWordStatus()
                    await getAvatarImage()
                })
                .catch((error) => console.log(error))
                .finally(() => closeRequest(requestId))
        },
        onError: (error) => {
            console.log('Login Failed:', error)
        },
        scope: "openid profile email",
        flow: "auth-code"
    })

    /////////////////////////////////////////////////////
    // microsoft login

    const generateCodeVerifier = () => {
        const array = new Uint8Array(32);
        crypto.getRandomValues(array);
        return Array.from(array).map(dec => ('0' + dec.toString(16)).substr(-2)).join('');
    };
    
    const generateCodeChallenge = async (codeVerifier) => {
        const base64URLEncode = (str) => {
            return btoa(String.fromCharCode.apply(null, new Uint8Array(str)))
                .replace(/\+/g, '-')
                .replace(/\//g, '_')
                .replace(/=+$/, '');
        };
    
        const sha256 = (plain) => {
            const encoder = new TextEncoder();
            const data = encoder.encode(plain);
            return crypto.subtle.digest('SHA-256', data);
        };
    
        const hash = await sha256(codeVerifier);
        return base64URLEncode(hash);
    };

    const microsoftLogin = () => {
        Promise.resolve()
            .then(() => generateCodeVerifier())
            .then(async (codeVerifier) => {
                const codeChallenge = await generateCodeChallenge(codeVerifier)
                return {codeVerifier, codeChallenge}
            })
            .then(async (obj) => {
                await overwriteAuthInfo({
                    issuerInfo: {
                        code_verifier: obj.codeVerifier,
                        code_challenge: obj.codeChallenge,
                        issuer: "microsoft.com"
                    }
                })
                const redirectUrl = window.location.origin.includes('localhost') ? 
                    process.env.REACT_APP_MSAL_REDIRECT_URL_LOCAL
                    :
                    process.env.REACT_APP_MSAL_REDIRECT_URL
                    return `https://login.microsoftonline.com/${process.env.REACT_APP_MSAL_TENANT_ID}/oauth2/v2.0/authorize
                        ?client_id=${process.env.REACT_APP_MSAL_CLIENT_ID}
                        &response_type=code
                        &redirect_uri=${encodeURIComponent(redirectUrl)}
                        &response_mode=query
                        &scope=openid profile email
                        &state=${getRandomInt(100000)}
                        &code_challenge=${encodeURIComponent(obj.codeChallenge)}
                        &code_challenge_method=S256`;                
            })
            .then((authUrl) => {
                window.location.href = authUrl;
            })
    }
    
    const microsoftSignUp = async () => {
        await instance.loginRedirect({
            ...loginRequest,
            prompt: "create",
        })
        .then((response) => console.log(`instance.loginRedirect(): ${response}`))
        .catch((error) => console.log(error))
        .finally(() => props.onLicenseAgreementDialogClose())
    }

    /////////////////////////////////////////////////////
    // LINE login

    const lineLogin = () => {
        Promise.resolve()
            .then(() => {
                const obj = {
                    issuer: "line.me",
                    state: `${getRandomInt(100000)}`
                }
                overwriteAuthInfo({issuerInfo: obj})
                return obj
            })
            .then((obj) => {
                const redirectUrl = window.location.origin.includes('localhost') ? 
                    process.env.REACT_APP_MSAL_REDIRECT_URL_LOCAL
                    :
                    process.env.REACT_APP_MSAL_REDIRECT_URL
    
                const authEndpoint = `https://access.line.me/oauth2/v2.1/authorize`
                    + `?response_type=code`
                    + `&client_id=${process.env.REACT_APP_LINE_AUTH_CLIENT_ID}`
                    + `&redirect_uri=${encodeURIComponent(redirectUrl)}`
                    + `&state=${obj.state}`
                    + `&scope=profile%20openid%20email`
                
                window.location.href = authEndpoint
            })
    }

    //////////////////////////////////////////////////////
    // get requestType

    const [requestType, setRequestType] = useState("")
    useEffect(() => {
        Promise.resolve()
            .then(() => getRequestType())
            .then((rt) => setRequestType(rt))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isOpen])

    //////////////////////////////////////////////////////

    return (
        <Modal
            closeOnOverlayClick={false}
            isOpen={props.isOpen} 
            onClose={props.onClose}
        >
            <ModalOverlay />
            <ModalContent
                maxW="340px"
            >
                <ModalHeader>
                    {requestType === "signUp" ? "サインアップ" : "サインイン"}
                </ModalHeader>                
                <ModalCloseButton 
                    onClick={() => {
                        props.onClose()
                    }}
                />                
                <ModalBody
                    m="16px 0px 0px 8px"
                    p="0px 8px 0px 8px"
                    style={{ maxHeight: "400px", overflowY: "auto"}}
                >
                    <Stack>
                        <HStack
                            onClick={async ()=>{
                                lineLogin()
                                props.onClose()
                            }}
                        >
                            <Box>
                                <Image
                                    src={LinePng}
                                    width="48px"
                                    height="48px"
                                />
                            </Box> 
                            <Box
                                style={{
                                    "fontWeight": "400",
                                    "font-size": ".9375rem"
                                }}
                            >
                                LINE で{requestType === "signUp" ? "サインアップ" : "サインイン"}
                            </Box>
                        </HStack>                        
                        <HStack
                            onClick={async ()=>{
                                googleLogin()
                                props.onClose()
                            }}
                        >
                            <Box>
                                <GoogleSvg
                                    width="48px"
                                    height="48px"
                                />
                            </Box> 
                            <Box
                                style={{
                                    "fontWeight": "400",
                                    "font-size": ".9375rem"
                                }}
                            >
                                Google で{requestType === "signUp" ? "サインアップ" : "サインイン"}
                            </Box>
                        </HStack>
                        {
                            !(getUserAgentInfo().os === "ios" && requestType === "signUp") ?
                                <>
                                    <HStack
                                        onClick={()=>{
                                            Promise.resolve()
                                                .then(() => overwriteAuthInfo({
                                                    issuerInfo: {
                                                        issuer:"microsoft.com"
                                                    }
                                                }))
                                                .then((authInfo) => {
                                                    if (authInfo.requestType === "signIn") {
                                                        microsoftLogin()
                                                    } else if (authInfo.requestType === "signUp") {
                                                        microsoftSignUp()
                                                    }
                                                })
                                                .then(() => props.onClose())
                                        }}
                                    >
                                        <Box>
                                            <MicrosoftSvg
                                                width="48px"
                                                height="48px"
                                            />
                                        </Box> 
                                        <Box
                                            style={{
                                                "fontWeight": "400",
                                                "font-size": ".9375rem"
                                            }}
                                        >
                                            Microsoft で{requestType === "signUp" ? "サインアップ" : "サインイン"}
                                        </Box>
                                    </HStack>
                                    {/* <Center
                                        style={{
                                            "fontWeight": "400",
                                            "font-size": ".9375rem"
                                        }}
                                    >
                                        ※現在 iOS でのサインアップは Google アカウントのみ<br/>サポートしています
                                    </Center> */}
                                </>
                                :
                                <></>
                        }
                        {/* <HStack
                            onClick={async ()=>{
                                await instance.loginRedirect({
                                    ...loginRequest,
                                    prompt: cookies?.loginPrompt === "signUp" ? "create" : "select_account",
                                    // loginHint: 'facebook-accounts',
                                    domainHint: 'facebook.com',
                                    //
                                    // authority: "https://facebook.com",
                                    // knownAuthorities: ["facebook.com"],
                                    // protocolMode: instance.ProtocolMode.OIDC,
                                    //  OIDCOptions: { "serverResponseType": instance.serverResponseType.QUERY, "defaultScopes": ["openid"] },
                                    // authorityMetadata: '{ "issuer": "https://www.facebook.com", "authorization_endpoint": "https://facebook.com/dialog/oauth/", "token_endpoint": "https://graph.facebook.com/oauth/access_token", "jwks_uri": "https://www.facebook.com/.well-known/oauth/openid/jwks/" }',
                                    
                                    // extraQueryParameters: {
                                    //     "domain_hint": "facebook.com",
                                    //     "domainHint": "facebook.com"
                                    // }
                                    

                                }).catch((error) => console.log(error))
                                props.onLoginRequestDialogClose()
                            }}
                        >
                            <Box>
                                <FacebookSvg
                                    width="48px"
                                    height="48px"
                                />
                            </Box> 
                            <Box
                                style={{
                                    "fontWeight": "400",
                                    "font-size": ".9375rem"
                                }}
                            >
                                Facebook で{cookies?.loginPrompt === "signUp" ? "サインアップ" : "サインイン"}
                            </Box>
                        </HStack> */}
                    </Stack>
                </ModalBody>
                <ModalFooter
                    justifyContent="flex-start"
                >
                    <Center
                        width="100%"
                    >
                        <Button
                            colorScheme="gray"
                            onClick={() => {
                                props.onClose()
                            }}
                        >
                            キャンセル
                        </Button>
                    </Center>
                </ModalFooter>
            </ModalContent>
        </Modal>        
    )
}

export default LoginRequestDialog