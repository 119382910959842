import React, { useState, useReducer, useEffect, useCallback, useRef } from 'react'
import DisplayWord from './display/DisplayWord'
import QuizWordChooseJapanese from './quiz/QuizWordChooseJapanese'
import QuizWordWriteEnglish from "./quiz/QuizWordWriteEnglish"
import CompleteQuiz from './quiz/CompleteQuiz'
import StudyWord from './study/StudyWord'
import NoWordToQuiz from "./quiz/NoWordToQuiz"
import DefaultPage from './DefaultPage'
import WordHistory from './history/WordHistory'
import WordList from './list/WordList'
import { buildQuizWordProps } from "./quiz/QuizWordPropsBuilder"
import { useUserContext } from '../context/userContext'
import { useFullCoverSpinnerContext } from "../context/fullCoverSpinnerContext"
import useApi from "../hooks/useApi"

const PageController = (props) => {

    const { 
        getQuizWords,
        getUserWordHistory
    } = useApi()

    const {
        getQuizMode
    } = useUserContext()
    
    const {
        openRequest,
        closeRequest
    } = useFullCoverSpinnerContext()

    ///////////////////////////////////////////////////////

    ////////////////////////////////////////////////////
    // SubApp launcher

    // back to default home page
    const backToHome = () => {
        dispatch({
            type: "backToHome"
        })
    }

    // quiz lancher
    const launchQuiz = async () => {
        let requestId;
        Promise.resolve()
            .then(() => requestId = openRequest())
            .then(() => getQuizWords("getQuizWords"))
            .then((quizWords) => {

                let handler = "QuizWordChooseJapanese"
                let mode = "chooseJapanese"
                if (getQuizMode() === "advanced") {
                    handler = "QuizWordWriteEnglish"
                    mode = "writeEnglish"
                }
    
                dispatch({
                    type: "quizInit",
                    data: {
                        previous: {},
                        current: {
                            handler,
                            mode,
                            isRandom: false,
                            quizWords
                        }
                    }
                })
            })
            .catch((error) => console.log(error))
            .finally(() => closeRequest(requestId))

    }

    // wordList launcher
    const launchWordList = () => {
        dispatch({
            type: "wordList"
        })
    }

    // wordHistory launcher
    const launchWordHistory = async () => {
        let requestId;
        Promise.resolve()
            .then(() => requestId = openRequest())
            .then(() => getUserWordHistory())
            .then((dailyGroupHistory) => {
                if (dailyGroupHistory) {
                    dispatch({
                        type: "wordHistory",
                        data: {
                            previous: {},
                            current: {
                                dailyGroupHistory
                            }
                        }
                    })
                }
            })
            .catch((error) => console.log(error))
            .finally(() => closeRequest(requestId))
    }

    useEffect(() => {
        // preprocess in case the action.type starts with "to"
        if (props.launchSubApp) {
            const handler = getHandler(stateStack)

            if (["QuizWordChooseJapanese", "QuizWordWriteEnglish"].includes(handler)) {
                dispatch({type:"browserBack"})
            } else {
                switch(props.launchSubApp) {
                    case "toHome":
                        backToHome()
                        break
                    case "toQuiz":
                        launchQuiz()
                        break
                    case "toWordList":
                        launchWordList()
                        break
                    case "toWordHistory":
                        launchWordHistory()
                        break
                    default:
                        break
                }
            }
        }
        props.setLaunchSubApp(null)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.launchSubApp])    

    /////////////////////////////////////////////////////////////////////////////
    // format of 'state'
    // const state = {
    //     handler: "",                     // page to render the original or complete data
    //     data: {                          // the format depends on the handler
    //         ...
    //     },                     
    // }

    const getHandler = (stateStack) => {
        if (Array.isArray(stateStack) && stateStack.length > 0) {
            return stateStack[0].handler
        } else {
            return ""
        }
    }

    const getData = (stateStack) => {
        if (Array.isArray(stateStack) && stateStack.length > 0) {
            return stateStack[0].data
        } else {
            return null
        }
    }

    const popState = (stateStack) => {
        let state = null
        if (Array.isArray(stateStack) && stateStack.length > 0) {
            while (true) {
                state = stateStack.shift()
                if (stateStack.length === 0) {
                    return state
                } else if (state.handler !== getHandler(stateStack)) {
                    return state
                } else {
                    // this is a workaround having duplicated states with <React.StrictMode/>
                    if (state.handler === "DisplayWord"
                            && state.data.displayWord.mode === "browseWord"
                            && getData(stateStack).displayWord.mode === "browseWord"
                            && getData(stateStack).displayWord.wordId === state.data.displayWord.wordId) {
                        // continue
                    } else {
                        return state
                    }
                }
            }
        }
        return state
    }

    // const clearStateStack = (stateStack) => {
    //     stateStack.splice(0)
    // }

    const setCompleteQuiz = (stateStack) => {
        const handler = getHandler(stateStack)
        if (handler.startsWith("QuizWord")) {
            const state = stateStack.shift()
            stateStack.unshift({
                handler: "CompleteQuiz",
                data: {...state.data}
            })
        } else {
            throw new Error(`stateStack[0].handler is ${stateStack.length > 0 ? stateStack[0].handler : null}`)
        }
    }
    
    const dedupeDisplayWordStack = (newStateStack, wordId) => {
        // to avoid duplicated state stack
        if (getHandler(newStateStack) === "DisplayWord"
                && getData(newStateStack).displayWord.mode === "browseWord"
                && getData(newStateStack).displayWord.ordId === wordId) {
            popState(newStateStack)
        }

        return newStateStack
    }

    ////////////////////////////////////////////////////
    // expected format of 'action'
    //
    // action = {
    //    type: [action type: string],          // action type
    //    data: {
    //        previous: {                       // data for previous stateStatck if necessarily
    //            ...
    //        },
    //        current: {                        // data for current stateStack
    //            ...
    //        } 
    //    }
    // }

    const nextFunc = (stateStack, action) => {
        let newStateStack = [...stateStack]

        // clean up useless stacks
        while (newStateStack.length > 0) {
            // if (getHandler(newStateStack) === "DisplayWord"
            //     && getData(newStateStack).displayWord.mode !== "browseWord") {
            //     popState(newStateStack)
            // } else 
            if (getHandler(newStateStack) === "DefaultPage") {
                popState(newStateStack)
            } else {
                break
            }
        }

        if (action.type === "browserBack") {
            const handler = getHandler(newStateStack)
            const data = getData(newStateStack)

            if (["QuizWordChooseJapanese", "QuizWordWriteEnglish"].includes(handler)
                    && data.answers.length > 0) {
                setCompleteQuiz(newStateStack)
            } else {
                popState(newStateStack)
            }
        } else if (action.type.startsWith("displayWord")) {

            const wordId = "wordId" in action.data.current ? action.data.current.wordId : ""
            let mode = undefined
            let editDialogParams = "editDialogParams" in action.data.current ? action.data.current.editDialogParams : {}
            let isEditted = "isEditted" in action.data.current ? action.data.current.isEditted : false
            let isNew = "isNew" in action.data.current ? action.data.current.isNew : false
            let isUserOriginWord = "isUserOriginWord" in action.data.current ? action.data.current?.isUserOriginWord : false
            // let savedAt = null
            const subMode = "subMode" in action.data.current ? action.data.current.subMode: undefined

            switch(action.type) {
                case "displayWordSwitchToEdit":
                    mode = "readyToEditWordElement"
                    editDialogParams = {isOpen: false}                   
                    break
                case "displayWordSwitchToBrowse":
                    mode = "browseWord"
                    editDialogParams = {isOpen: false}
                    break
                case "displayWordEditDialogOpened":
                    mode = "editWordElement"
                    isEditted = false
                    break
                case "displayWordEditDialogCommitted":
                    if (subMode === "wordDelete"
                        && ["DisplayWord"].includes(getHandler(newStateStack))) {
        
                        // back to DefaultPage
                        popState(newStateStack)
                        newStateStack.unshift({
                            hander: "DefaultPage",
                            data: {}
                        })
                    } else {
                        newStateStack = dedupeDisplayWordStack(newStateStack, wordId)
                        mode = "readyToEditWordElement"
                        editDialogParams = {isOpen: false}
                        isEditted = true
                        // savedAt = getNowInIso8601Jst()
                    }
                    break
                case "displayWordEditDialogCanceled":
                    if (isNew && isUserOriginWord && !isEditted) {
                        mode = "registerNewUserWord"
                    } else {
                        mode = "readyToEditWordElement"
                    }
                    editDialogParams = {isOpen: false}
                    break
                case "displayWordSaved":
                    newStateStack = dedupeDisplayWordStack(newStateStack, wordId)
                    mode = "browseWord"
                    editDialogParams = {isOpen: false}
                    isEditted = false
                    // savedAt = getNowInIso8601Jst()
                    break
                case "displayWordClose":
                    popState(newStateStack)
                    if (!(["WordHistory", "CompleteQuiz"].includes(getHandler(newStateStack)))) {
                        throw new Error(`${getHandler(newStateStack)} is an invalid handler`)
                    }
                    break                    
                default:
                    throw new Error(`action.type:${action.type} is undefined`)
            }

            if (mode) {
                newStateStack.unshift({
                    handler: "DisplayWord",
                    data: {
                        displayWord: {
                            wordId,
                            mode,
                            editDialogParams,
                            isEditted,
                            // savedAt
                        },
                        search: {
                            ...action.data.previous.search
                        }
                    }
                })
            }
        } else if (action.type.startsWith("quiz")) {

            switch (action.type) {
                case "quizInit":
                    // this seems too aggressive
                    // clearStateStack(newStateStack)

                    // in case of starting the next quiz
                    if (action.data.current.quizWords.length > 0) {
                        newStateStack.unshift({
                            handler: action.data.current.handler,
                            data: {
                                ...action.data.current,
                                answers:[]
                            }
                        })
                    } else {
                        newStateStack.unshift({
                            handler: "NoWordToQuiz",
                            data: {
                                mode: action.data.current.mode
                            }
                        })  
                    }                    
                    break
                case "quizAnswer":
                    const handler = getHandler(newStateStack)
                    if (handler.startsWith("QuizWord")) {
                        const data = getData(newStateStack)
        
                        if (data.answers.length === 0 ||
                            data.answers[data.answers.length - 1].cId !== action.data.current.cId) {
                            data.answers.push(action.data.current)
                        }
                        
                        if (data.quizWords.length <= data.answers.length) {
                            // CompleteQuiz
                            setCompleteQuiz(newStateStack)
                        }
                    } else {
                        throw new Error(`${getHandler(newStateStack)} is an invalid handler`)
                    }
                    break
                default:
                    throw new Error(`action.type:${action.type} is undefined`)
            }
        } else if (action.type.startsWith("study")) {
            switch (action.type) {
                case "studyInit":
                    if (getHandler(newStateStack) === "DisplayWord") {
                        getData(newStateStack).scrollPosition = action.data.previous.scrollPosition
                        newStateStack.unshift({
                            handler: "StudyWord",
                            data: {
                                wordId: action.data.current.wordId 
                            }
                        })                           
                    } else {
                        throw new Error(`${getHandler(newStateStack)} is an invalid handler`)
                    }
                    break
                case "studyComplete":
                    popState(newStateStack)
                    if (["DisplayWord", "WordHistory"].includes(getHandler(newStateStack))) {
                        //
                    } else {
                        throw new Error(`${getHandler(newStateStack)} is an invalid handler`)
                    }
                    break
                default:
                    throw new Error(`action.type:${action.type} is undefined`)
            }
        } else if (action.type === "selectWord") {
            if (["WordHistory", "WordList"].includes(getHandler(newStateStack))) {
                getData(newStateStack).viewSettings = action.data.previous.viewSettings
                getData(newStateStack).dataRepo = action.data.previous.dataRepo
                getData(newStateStack).scrollPosition = action.data.previous.scrollPosition
            } else if (["CompleteQuiz"].includes(getHandler(newStateStack))) {
                getData(newStateStack).scrollPosition = action.data.previous.scrollPosition
            } else {
                throw new Error(`stateStack.length <= 0`)
            }
            const wordId = action.data.current.wordId
            const query = action.data.current.query

            newStateStack.unshift({
                handler: "DisplayWord",
                data: {
                    displayWord: {
                        wordId,
                        mode: "browseWord",
                        editDialogParams: {isOpen: false},
                        isEditted: false
                    },
                    search: {
                        query,
                        selectedWordId: wordId,
                        candidates:[],
                        hasPerfectMatch: true
                    }
                }
            })             
        
        } else if (action.type === "search") {

            let state    // referring to 'current' or 'previous'
            if (Object.keys(action.data.current).length > 0) {
                state = action.data.current
            } else if (Object.keys(action.data.previous).length > 0) {
                state = action.data.previous
            } else {
                throw new Error(`action.type: ${action.type} does not have data either action.data.current or action.data.previous`)
            }
            
            const selectedWordId = state.searchResult.selectedWordId
            const hasPerfectMatch = state.searchResult.candidates.some((candidate) => candidate.matchStatus === "perfect")
            
            // this will be assigned in DisplayWord.js
            // if 1) there is no matchStatus === "perfect" AND 2) none of the candidate is selected
            const mode = !selectedWordId && !hasPerfectMatch ? "empty" : undefined
            
            // to avoid duplicated state stack
            if (getHandler(newStateStack) === "DisplayWord"
                && getData(newStateStack).displayWord.wordId === selectedWordId) {
                popState(newStateStack)
            }

            newStateStack.unshift({
                handler: "DisplayWord",
                data: {
                    displayWord: {
                        wordId: selectedWordId,
                        mode,
                        editDialogParams: {isOpen: false},
                        isEditted: false,
                        query: state.query
                    },
                    search: {
                        query: state.query,
                        selectedWordId,
                        candidates: state.searchResult.candidates,
                        hasPerfectMatch
                    }
                }
            })
        } else if (action.type === "wordHistory") {
            newStateStack.unshift({
                handler: "WordHistory",
                data: {
                    dailyGroupHistory: action.data.current.dailyGroupHistory
                }
            })
            console.log("")
        } else if (action.type === "wordDelete") {
            if (["DisplayWord"].includes(getHandler(newStateStack))) {
                popState(newStateStack)
            } else {
                throw new Error(`${getHandler(newStateStack)} is an invalid handler`)
            }
        } else if (action.type === "wordList") {
            const stateStack = {
                handler: "WordList",
                data: {}
            }
            if (action?.data?.current?.daysUntilExpire !== undefined) {
                stateStack["data"]["daysUntilExpire"] = action.data.current.daysUntilExpire
            }
            newStateStack.unshift(stateStack)

        } else if (action.type === "error") {
            popState(newStateStack)
            newStateStack.unshift({
                handler: "DefaultPage",
                data: {}
            })
        } else if (action.type === "dwell") {
            // do nothing, stay on the same page
        } else if (action.type === "backToHome") {
            if (newStateStack.length > 0) {
                newStateStack.unshift({
                    handler: "DefaultPage",
                    data: {}
                })
            }
        } else if (action.type === "clickLink") {
            const wordId = action.data.current.wordId
            const displayName = action.data.current.displayName

            newStateStack.unshift({
                handler: "DisplayWord",
                data: {
                    displayWord: {
                        wordId,
                        mode: "browseWord",
                        editDialogParams: {isOpen: false},
                        isEditted: false
                    },
                    search: {
                        query: displayName,
                        selectedWordId: wordId,
                        candidates:[],
                        hasPerfectMatch: true
                    }
                }
            })
        } else {
            if (newStateStack.length > 0) {
                newStateStack.unshift({
                    handler: "DefaultPage",
                    data: {}
                })
            }
        }

        return newStateStack
    }

    const [stateStack, dispatch] = useReducer(nextFunc, [])

    //////////////////////////////////////////////////////////////
    // Browser back handling

    const timeRef = useRef(0)
    const blockBrowserBack = useCallback((e) => {
        window.history.go(1)

        // in case of React.StrictMode (for INT), ignore the 2nd execution.
        const elapsedTime = Date.now() - timeRef.current
        if (process.env.REACT_APP_ENV === "PROD"
               || elapsedTime >= 1000) {
            dispatch({type: "browserBack"})
        }
        timeRef.current = Date.now()
    }, [])
    
    useEffect(() => {
        window.history.pushState(null, '', window.location.href)
        window.addEventListener('popstate', blockBrowserBack)
        return () => {
            window.removeEventListener('popstate', blockBrowserBack)
        }
    }, [blockBrowserBack])


    ///////////////////////////////////////////////////
    // When getHander(stateStack) was directly used in the return clause,
    // "Uncaught TypeError: getHandler(...) is undefined" occured.
    // getData(...) caused the same error too.
    // As the workaround, those values are assigned with useState(), 
    // and specificed in useEffect()
    // Since headState.handler could be undefined occasionally somehow,
    // headState.hander !== undefined is neccessary

    const [headState, setHeadState] = useState({
        handler:"DefaultPage",
        data: {}
    })

    useEffect(() => {
        console.log(`getHandler(stateStack): ${getHandler(stateStack)}`)
        setHeadState({
            handler: getHandler(stateStack),
            data: getData(stateStack)
        })
    }, [stateStack])

    ///////////////////////////////////////////////////


    return (
        headState.handler 
                && headState.handler.startsWith("QuizWordChooseJapanese") 
                && headState.data.quizWords.length > headState.data.answers.length ?
            <QuizWordChooseJapanese
                params={buildQuizWordProps(
                    headState.data
                )}
                dispatch={dispatch}
                setLaunchSubApp={props.setLaunchSubApp}
            />
            :
            headState.handler
                    && headState.handler.startsWith("QuizWordWriteEnglish")
                    && headState.data.quizWords.length > headState.data.answers.length ?
                <QuizWordWriteEnglish
                    params={buildQuizWordProps(
                        headState.data
                    )}
                    dispatch={dispatch}
                    setLaunchSubApp={props.setLaunchSubApp}
                />
                :
                headState.handler && headState.handler === "StudyWord" ? 
                    <StudyWord
                        wordId={headState.data.wordId}
                        dispatch={dispatch}
                        setLaunchSubApp={props.setLaunchSubApp}
                    />
                    :
                    headState.handler && headState.handler.startsWith("CompleteQuiz") ?
                        <CompleteQuiz
                            data={headState.data}
                            dispatch={dispatch}
                            setLaunchSubApp={props.setLaunchSubApp}
                        />
                        :
                        headState.handler && headState.handler.startsWith("NoWordToQuiz") ?
                            <NoWordToQuiz
                                mode={headState.handler.endsWith("ChooseJapanese") ? "chooseJapaneseRandom" : "writeEnglishRandom"}
                                dispatch={dispatch}
                                setLaunchSubApp={props.setLaunchSubApp}
                            />
                            :
                            headState.handler && headState.handler === "DisplayWord" ?
                                <DisplayWord
                                    // displayWordParams={buildDisplayWordProps(
                                    //     headState.data.displayWord.wordData,
                                    //     headState.data.displayWord.editDialogParams,
                                    //     headState.data.displayWord.mode,
                                    //     headState.data.displayWord.isEditted,
                                    //     getUserId(),
                                    //     getSases()
                                    // )}
                                    displayWordParams = {{
                                        wordId: headState.data.displayWord.wordId,
                                        editDialogParams: headState.data.displayWord.editDialogParams,
                                        mode: headState.data.displayWord.mode,
                                        isEditted: headState.data.displayWord.isEditted,
                                        // savedAt: headState.data.displayWord.savedAt
                                        // userId: getUserId(),
                                        // sases: getSases()
                                    }}
                                    searchParams={headState.data.search}
                                    scrollPosition={headState.data?.scrollPosition}
                                    dispatch={dispatch}
                                    setLaunchSubApp={props.setLaunchSubApp}
                                />
                                :
                                headState.handler && headState.handler === "WordHistory" ?
                                    <WordHistory
                                        dailyGroupHistory={headState.data.dailyGroupHistory}
                                        scrollPosition={headState.data?.scrollPosition}
                                        dispatch={dispatch}
                                        setLaunchSubApp={props.setLaunchSubApp}
                                    />
                                    :
                                    headState.handler && headState.handler === "WordList" ?
                                        <WordList
                                            viewSettings={headState.data?.viewSettings}
                                            dataRepo={headState.data?.dataRepo}
                                            daysUntilExpire={headState.data?.daysUntilExpire !== undefined ? headState.data.daysUntilExpire : 999999}
                                            scrollPosition={headState.data?.scrollPosition}
                                            dispatch={dispatch}
                                            setLaunchSubApp={props.setLaunchSubApp}
                                        />
                                        :
                                        <DefaultPage
                                            query={""}
                                            dispatch={dispatch}
                                            backToHome={backToHome}
                                            launchQuiz={launchQuiz}
                                            launchWordList={launchWordList}
                                            launchWordHistory={launchWordHistory}
                                            setLaunchSubApp={props.setLaunchSubApp}

                                        />
    )
}

export default PageController