import {
    mergeObjects
} from "../CommonFunction"

const useAsyncLocalStorage = () => {

    const setItem = (key, value) => {
        return Promise.resolve()
            .then(() => localStorage.setItem(key, value))
    }

    const getItem = (key) => {
        return Promise.resolve()
            .then(() => {return localStorage.getItem(key)})
    }

    const removeItem = (key) => {
        return Promise.resolve()
            .then(() => localStorage.removeItem(key))
    }

    const clearLocalStorage = () => {
        return Promise.resolve()
            .then(() => localStorage.clear())
    }

    const setAuthInfo = (obj) => {
        return Promise.resolve()
            .then(() => localStorage.setItem("authInfo", JSON.stringify(obj)))
    }

    const getAuthInfo = () => {
        return Promise.resolve()
            .then(() => {
                const authInfoSrc = localStorage.getItem("authInfo")
                if (authInfoSrc) {
                    return JSON.parse(authInfoSrc)
                } else {
                    return {}
                }
            })
    }

    const overwriteAuthInfo = (obj) => {
        return Promise.resolve()
            .then(() => getAuthInfo())
            .then((authInfo) => mergeObjects(authInfo, obj))
            .then((authInfo) => {
                setAuthInfo(authInfo)
                return authInfo
            })
    }

    return {
        getItem,
        setItem,
        removeItem,
        clearLocalStorage,
        getAuthInfo,
        setAuthInfo,
        overwriteAuthInfo
    }
}

export default useAsyncLocalStorage


// [REFERENCE]

    // const initialAuthInfo = {
    //     requestType: "",
    //     issuerInfo: {
    //         issuer: "",
    //         authCode: "",
    //         idToken: "",
    //         accessToken: "",
    //         refreshToken: "",
    //         expiredAt: ""
    //     },
    //     azureInfo: {
    //         accessToken: "",
    //         xUserInfo: "",
    //         expiredAt: "2000-01-01T00:00:00+09:00",
    //         waitNextRequestUntil: getNowInIso8601Jst()
    //     }
    // }

