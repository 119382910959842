import { useState } from "react"
import { useMsal } from '@azure/msal-react'
import axios from 'axios'
import axiosRetry from 'axios-retry'
import { apiRequest, authRequest } from "../authConfig"
import {
    convertDatesToIso8601Jst,
    mergeObjects
} from "../CommonFunction"
import { useAuthContext } from "../context/authContext"
import userAsyncLocalStorage from "../hooks/useAsyncLocalStorage"

const useSubmit = () => {
    
    const {
        instance
    } = useMsal()
    
    const {
        switchToSignOut,
        validateAccessToken
    } = useAuthContext()

    const {
        setAuthInfo,
        getAuthInfo
    } = userAsyncLocalStorage()
    
    const [requestIds, setRequestIds] = useState({})
    const timeoutInMsec = 20000
    const maxRetryCount = 3


    const getBearerToken = async () => {

        const authInfo = await getAuthInfo()
        if (Object.keys(authInfo).length > 0) {
            if (await validateAccessToken(authInfo)) {
                return {
                    "accessToken": authInfo.azureInfo.accessToken,
                    "xUserInfo": authInfo.azureInfo.xUserInfo
                }
            } else if (authInfo.issuerInfo.refreshToken) {
                // get accessToken using refreshToken
                const requestBody = {
                    requestType: "refresh",
                    issuer: authInfo.issuerInfo.issuer,
                    refreshToken: authInfo.issuerInfo.refreshToken
                }

                if (authInfo.issuerInfo.issuer === "line.me") {
                    requestBody.idToken = authInfo.issuerInfo.idToken
                }

                const response = await submit(
                    authRequest.url,
                    requestBody,
                    null,
                    false
                )

                if (response.status === "success") {
                    const updateAuthInfo = mergeObjects(authInfo, response.result)
                    await setAuthInfo(updateAuthInfo)

                    if (response.result.azureInfo.accessToken) {
                        return {
                            "accessToken": response.result.azureInfo.accessToken,
                            "xUserInfo": response.result.azureInfo.xUserInfo
                        }
                    } else {
                        // TBD: promot the user to sign in again
                        switchToSignOut()
                    }
                }
            }
        }

        return {
            "accessToken": "",
            "xUserInfo": ""
        }
    }

    const getMsalAuthResponse = async () => {
        await instance.initialize();
    
        return new Promise((resolve, reject) => {
            let count = 0
            const intervalId = setInterval(async () => {
                const account = instance.getActiveAccount()
    
                if (account) {
                    clearInterval(intervalId)
                    try {
                        const response = await instance.acquireTokenSilent({
                            ...apiRequest,
                            account
                        });
                        resolve(convertDatesToIso8601Jst(response))
                    } catch (error) {
                        reject(error)
                    }
                } else if (++count > 50) {
                    clearInterval(intervalId)
                    resolve(null)
                }
            }, 100)
        })
    }    
  
    const submit = async (url, requestBody, requestId=null, needBearerToken=true, headers={}) => {

        let token = null
        if (needBearerToken) {
            token = await getBearerToken(false)
        }

        let response = {}

        if (needBearerToken
            && !token.accessToken) {
            // error
            response = {
                status: "fail",
                statusCode: "1",
                message: "cannot get accessToken"
            }            
        } else {
            if (requestId !== null) {
                setRequestIds({
                    ...requestIds,
                    [requestId]: true
                })
            }

            if (Object.keys(headers).length === 0) {
                headers = {
                    "Content-Type": "application/json"
                }
            }

            if (needBearerToken) {
                headers = {
                    ...headers,
                    "Authorization": `Bearer ${token.accessToken}`,
                    "X-UserInfo": token.xUserInfo
                }
            }

            try {
                axiosRetry(axios, {
                    retries: maxRetryCount,
                    shouldResetTimeout: true,
                    retryCondition: (_error) => true // retry no matter what
                })
                const res = await axios.post(url, requestBody, {headers: headers, timeout: timeoutInMsec})
                response = res.data
            } catch (error) {
                response = {
                    status: "fail",
                    statusCode: error && "statusCode" in error ? error.statusCode : -1,
                    message: error && "message" in error ? error.message : "something went wrong."
                }
            } finally {
                if (requestId !== null) {
                    setRequestIds({
                        ...requestIds,
                        [requestId]: false
                    })
                }
            }
        }

        return response
    }

    const isLoading = (requestId) => {
        return requestId in requestIds ? requestIds[requestId] : null
    }

    return {
        apiEndPoint: apiRequest.url,
        authEndPoint: process.env.REACT_APP_AUTH_TOKENS_URL,
        isLoading,
        submit,
        getMsalAuthResponse,
        getBearerToken
    }
}
  
export default useSubmit;
