import React from "react";
import {
    Box,
    HStack,
    Spacer,
    Stack
} from "@chakra-ui/react";
// import '@splidejs/react-splide/css';
import {
    AddIcon,
    EditIcon,
    DeleteIcon
} from '@chakra-ui/icons'
import '@splidejs/react-splide/css';


import "../../App.css";
import {
    posEtoJ,
    getValueFromWord,
    getPosesLength,
    getElementIdByIndex
} from "../../WordDataFunction"
import MeaningList from "./MeaningList";
import SoundEffect from "../SoundEffect"

const PosList = (props)  => {
  
    const posList = []

    if (props.displayWordParams.wordData?.poses) {
        props.displayWordParams.wordData.poses.forEach((posNode, posIndex) => {
            let idMap = getElementIdByIndex(
                props.displayWordParams.wordData,
                "pos",
                posIndex)            

            const ipa = getValueFromWord(
                props.displayWordParams.wordData,
                "posWordIpa",
                posIndex)
            
            const wordAudioList = getValueFromWord(
                    props.displayWordParams.wordData,
                    "posWordAudio",
                    posIndex
                ).map((url) =>
                    <SoundEffect
                        key={url}
                        url={url}
                        size={36}
                    />
                )

            posList.push(
                <Box
                    key={`pos_${posIndex}`}
                    border="1px"
                    borderColor="blue.100"
                    m="2px"
                    p="2px"
                >
                    <HStack>
                        <Box
                            bgColor="blue.600"
                            fontSize="2xl"
                            color="white"
                            p="0px 4px 0px 4px"
                        >
                            {posEtoJ(posNode.pos)}
                        </Box>
                        {
                            ipa ?
                                <Box
                                    pl="4px"
                                >
                                    {ipa}
                                </Box>
                                :
                                <></>
                        }
                        <HStack spacing={2}>
                            {wordAudioList}
                        </HStack>
                        <Spacer/>
                        {
                            props.displayWordParams.mode === "readyToEditWordElement" ?
                                <>
                                <AddIcon
                                    onClick={() => {
                                        if (!props.displayWordParams.exceedMaxUserPosCount) {
                                            props.dispatch({
                                                type: "displayWordEditDialogOpened",
                                                data: {
                                                    previous:{
                                                        search: props.searchParams
                                                    },
                                                    current: {
                                                        wordId: props.displayWordParams.wordId,
                                                        editDialogParams: {
                                                            type: "posAdd",
                                                            header: `新しい品詞を追加します`,
                                                            displayName: props.displayWordParams.displayName,
                                                            inusedPoses: props.displayWordParams.inusedPoses,
                                                            meaning: "",
                                                            sentence: "",
                                                            sentenceJa: "",                                                        
                                                            idMap: {
                                                                pos: "",
                                                                meaningId: crypto.randomUUID(),
                                                                sentenceId: crypto.randomUUID()
                                                            },
                                                            isOpen: true
                                                        },
                                                        isEditted: props.displayWordParams.isEditted
                                                    }
                                                }
                                            })
                                        }
                                    }}
                                    color={
                                        props.displayWordParams.exceedMaxUserPosCount ?
                                            "gray.300"
                                            :
                                            "black"
                                    }
                                />
                                {
                                    posNode.origin === "user" ?
                                        <>
                                            <EditIcon
                                                onClick={() => {
                                                    props.dispatch({
                                                        type: "displayWordEditDialogOpened",
                                                        data: {
                                                            previous:{
                                                                search: props.searchParams
                                                            },
                                                            current: {
                                                                wordId: props.displayWordParams.wordId,
                                                                editDialogParams: {
                                                                    type: "posEdit",
                                                                    header: "発音または品詞を変更します",
                                                                    displayName: props.displayWordParams.displayName,
                                                                    ipa,
                                                                    pos: posNode.pos,
                                                                    inusedPoses: props.displayWordParams.inusedPoses,
                                                                    idMap,
                                                                    isOpen: true
                                                                },
                                                                isEditted: props.displayWordParams.isEditted
                                                            }
                                                        }
                                                    })
                                                }}
                                            />
                                            {
                                                getPosesLength(props.displayWordParams.wordData) > 1 ?
                                                    <DeleteIcon
                                                        onClick={() => {
                                                            props.dispatch({
                                                                type: "displayWordEditDialogOpened",
                                                                data: {
                                                                    previous:{
                                                                        search: props.searchParams
                                                                    },
                                                                    current: {
                                                                        wordId: props.displayWordParams.wordId,
                                                                        editDialogParams: {
                                                                            type: "posDelete",
                                                                            header: `品詞：[${posEtoJ(posNode.pos)}] を削除します。この品詞に設定されたすべての情報が削除されます。※削除すると復元することはできません`,
                                                                            idMap,
                                                                            isOpen: true
                                                                        },
                                                                        isEditted: props.displayWordParams.isEditted
                                                                    }
                                                                }
                                                            })
                                                        }}                                                
                                                    />
                                                    :
                                                    <></>
                                            }
                                        </>
                                        :
                                        <></>
                                    
                                }                                
                                </>
                                :
                                <></>
                        }
                    </HStack>
                    {
                        // additionalInfo
                        "additionalInfo" in posNode
                                && posNode.additionalInfo.constructor === Object
                                && Object.keys(posNode.additionalInfo).length > 0 ?
                            <HStack>
                                {
                                    posNode.additionalInfo?.alternativeSpellingOf ?
                                        <HStack>
                                            <Box>別表記</Box>
                                            <Box>{posNode.additionalInfo.alternativeSpellingOf}</Box>
                                        </HStack>
                                        :
                                        <></>
                                }
                                {
                                    posNode.additionalInfo?.obsoleteSpellingOf ?
                                        <HStack>
                                            <Box>旧表記</Box>
                                            <Box>{posNode.additionalInfo.obsoleteSpellingOf}</Box>
                                        </HStack>
                                        :
                                        <></>
                                }
                                {
                                    posNode.additionalInfo?.synonymOf ?
                                        <HStack>
                                            <Box>同義語</Box>
                                            <Box>{posNode.additionalInfo.synonymOf}</Box>
                                        </HStack>
                                        :
                                        <></>
                                }
                            </HStack>
                            :
                            <></>
                    }
                    <HStack>
                    {
                        // countability
                        "countability" in posNode
                                && posNode.countability.constructor === Object
                                && Object.keys(posNode.countability).length > 0 ?
                            Object.keys(posNode.countability).map((k) => {
                                let label, title;
                                if (k === "countable" && posNode.countability.countable) {
                                    label = "Ｃ"
                                    title = "可算名詞"
                                } else if (k === "uncountable" && posNode.countability.uncountable) {
                                    label = "Ｕ"
                                    title = "不可算名詞"
                                } else if (k === "pluralOnly" && posNode.countability.pluralOnly) {
                                    label = "pl"
                                    title = "複数形で使われる可算名詞"
                                }

                                if (label) {
                                    return <Box
                                        key={`${posNode.pos}_${k}`}
                                        bgColor="gray.400"
                                        color="white"
                                        p="0px 4px 0px 4px"
                                        m="2px 0px 2px 2px"
                                        title={title}>
                                        {label}
                                    </Box>
                                } else {
                                    return <></>
                                }
                                

                            })
                            :
                            <></>
                    }
                    {
                        // inflections
                        ["Noun", "Proper noun", "Verb", "Adjective", "Adverb"].includes(posNode.pos) ?
                            <Box>
                                {
                                    ["Noun", "Proper noun"].includes(posNode.pos) ?
                                        <HStack>
                                            <Box fontSize="xs" borderWidth="1px" borderColor="gray.400" padding="0px 2px 0px 2px">単数</Box>
                                            <Box pb="2px">{posNode.inflections?.singular ? posNode.inflections.singular : "-"}</Box>
                                            <Box fontSize="xs" borderWidth="1px" borderColor="gray.400" padding="0px 2px 0px 2px">複数</Box>
                                            <Box pb="2px">
                                                {
                                                    posNode.inflections?.plural ?
                                                        Array.isArray(posNode.inflections.plural) ?
                                                            posNode.inflections.plural.join(", ")
                                                            :
                                                            posNode.inflections.plural
                                                        :
                                                        "-"

                                                }
                                            </Box>
                                        </HStack>
                                        :
                                        ["Verb"].includes(posNode.pos) ?
                                            <HStack>
                                                <Box fontSize="xs" borderWidth="1px" borderColor="gray.400" padding="0px 2px 0px 2px">三単現</Box>
                                                <Box pb="2px">
                                                    {
                                                        posNode.inflections?.ss 
                                                                && posNode.inflections.ss.length > 0 ?
                                                            posNode.inflections.ss.join(", ")
                                                            :
                                                            "-"
                                                    }
                                                </Box>
                                                <Box fontSize="xs" borderWidth="1px" borderColor="gray.400" padding="0px 2px 0px 2px">現在分詞</Box>
                                                <Box pb="2px">
                                                    {
                                                        posNode.inflections?.ings 
                                                                && posNode.inflections.ings.length > 0 ?
                                                            posNode.inflections.ings.join(", ")
                                                            :
                                                            "-"
                                                    }
                                                </Box>
                                                <Box fontSize="xs" borderWidth="1px" borderColor="gray.400" padding="0px 2px 0px 2px">過去</Box>
                                                <Box pb="2px">
                                                    {
                                                        posNode.inflections?.pasts 
                                                                && posNode.inflections.pasts.length > 0 ?
                                                            posNode.inflections.pasts.join(", ")
                                                            :
                                                            "-"
                                                    }
                                                </Box>
                                                <Box fontSize="xs" borderWidth="1px" borderColor="gray.400" padding="0px 2px 0px 2px">過去分詞</Box>
                                                <Box pb="2px">
                                                    {
                                                        posNode.inflections?.past2s 
                                                                && posNode.inflections.past2s.length > 0 ?
                                                            posNode.inflections.past2s.join(", ")
                                                            :
                                                            "-"
                                                    }
                                                </Box>
                                            </HStack>
                                            :
                                            // ["Adjective", "Adverb"].includes(posNode.pos)
                                            <HStack>
                                                <Box fontSize="xs" borderWidth="1px" borderColor="gray.400" padding="0px 2px 0px 2px">比較級</Box>
                                                <Box pb="2px">
                                                    {
                                                        posNode.inflections?.comparative 
                                                                && posNode.inflections.comparative.length > 0 ?
                                                            posNode.inflections.comparative.join(", ")
                                                            :
                                                            "-"
                                                    }
                                                </Box>
                                                <Box fontSize="xs" borderWidth="1px" borderColor="gray.400" padding="0px 2px 0px 2px">最上級</Box>
                                                <Box pb="2px">
                                                    {
                                                        posNode.inflections?.superlative 
                                                                && posNode.inflections.superlative.length > 0 ?
                                                            posNode.inflections.superlative.join(", ")
                                                            :
                                                            "-"
                                                    }
                                                </Box>
                                            </HStack>
                                }

                            </Box>
                            :
                            <></>
                    }
                    </HStack>
                    <MeaningList
                        displayWordParams={props.displayWordParams}
                        searchParams={props.searchParams}
                        pos={posNode.pos}
                        posIndex={posIndex}
                        ipa={ipa}
                        meanings={posNode?.meanings}
                        dispatch={props.dispatch}
                        userSentenceImages={props.userSentenceImages}
                        setUserSentenceImages={props.setUserSentenceImages}
                        imageRepo={props.imageRepo}
                    />
                </Box>
            )
        })
    }

    return (
        <Stack
            mb="15px"
        >
            {posList}
        </Stack>
    )        
}

export default PosList